.search-toggle {
    padding: 0.375rem 0.75rem;
    line-height: 1;
    
    &.mobile {
        display: none;
    }

    .text {
        display: none;
    }
}

.search-offcanvas.offcanvas {
    overflow-y: visible;
    position: absolute;
    min-height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;

    .block-search {
        width: 100%;
    }

    .form-group {
        .input-group {
            max-width: 71.25rem;
            margin: 0 auto;
            position: static;
            input {
                border: none;
                &:focus {
                    box-shadow: none;
                }
            }

            .mst-searchautocomplete {
                &__autocomplete {
                    max-width: 71.25rem;
                    top: 100% !important;
                    left: 50% !important;
                    transform: translate(-50%, 0);
                    min-height: auto;
                    border: none;
                }

                &__result {
                    max-height: 50vh;
                    overflow-y: scroll;
                }
            }

            .action.search {
                font-size: 1rem;
                padding: 0.2rem;
                pointer-events: none;
                &:hover {
                    background: initial;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .search-toggle {
        &.mobile {
            display: block;
            margin-left: 0.5rem;
        }

        &.desktop {
            display: none;
        }
    }
}

.block-search {
    .actions {
        .search {
            &::before {
                @include svg-icon(1rem, 1rem, $icon-search-hover, $gray-900);
                vertical-align: middle;
            }
            @include button-variant($btn-search, $btn-search);
        }
    }
}