.footer {
    .payments {
        font-size: 0;
        display: flex;
        justify-content: center;
        img {
            filter: grayscale(100%);
        }
    }

    .footer-middle-wrapper {
        padding: 3.438rem 0;
        border-top: 1px solid $grey-e6;
        border-bottom: 1px solid $grey-e6;
        
        .col-md-3 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            .footer-socials {
                text-align: left;
                line-height: initial;
                ul {
                    li {
                        margin-right: 0.313rem;
                        a {
                            color: $black;
                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                .footer-socials,
                .payments {
                    padding: 0;
                    display: none;
                }
            }
        }

        .footer-socials {
            display: block;
        }

        .footer-payments {
            margin-bottom: 0;
            display: none;
        }

        @include media-breakpoint-down(md) {
            .footer-socials {
                padding: 0;
                a {
                    width: auto;
                    height: auto;
                }
            }

            .footer-block {
                &.block-address {
                    display: block;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .footer-payments {
                display: block;
            }
        }
    }

    .footer-bottom-wrapper {
        padding: 3.438rem 0;
        
        .footer-logo {
            .logo {
                display: block;
                max-width: 170px;
                margin: 0 auto 2.438rem;
            }

            .copyright {
                display: block;
                color: $black;
                font-size: 0.75rem;
                font-weight: $font-weight-light;
                text-align: center;
            }
        }

        .contact-info {
            padding: 0;
            margin: 0;
            li,
            a {
                color: $black;
            }
        }

        .block-payments {
            margin-bottom: 3rem;
        }
        .payments {
            img {
                height: 18px;
            }
        }
        
        .google-captcha {
            display: block;
            margin-top: 2rem;
            margin-bottom: 1rem;
            color: $gray-300;
            font-size: $font-size-sm;
            text-align: center;

            a {
                color: $gray-300;
            }
        }
    }

    .footer-bottom-wrapper,
    .footer-middle-wrapper {
        background: $white;
    }

    .block-title {
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: $font-weight-light;
        margin-bottom: 1.25rem;
    }

    .footer-block {
        li {
            font-weight: $font-weight-light;
            font-size: 0.813rem;

            a {
                display: inline-block;
                line-height: 1;
            }
        }
    }
}

footer.footer {
    border-top: 1px solid $gray-b2;
    padding-top: 0;
}

.grecaptcha-badge { 
    visibility: hidden; 
} 

$icons: (
    facebook: $icon-facebook,
    twitter: $icon-twitter,
    instagram: $icon-instagram,
);

$iconWidthBlock: 1rem;
$iconWidthSocial: 1.5rem;

@each $class,
$icon in $icons {
    .footer {
        .footer-socials {
            .icon {
                &-#{$class} {
                    @include svg-icon($iconWidthSocial, $iconWidthSocial, $icon, $primary);
                    vertical-align: middle;
                }
            }
        }
    }
}