.catalog-product-view {
    .page-main {
        margin-bottom: 3rem;

        .sticky-block {
            position: relative;
        }

        .product-row {
            margin-bottom: 2rem;
        }

        .product-media,
        .product-info-main {
            z-index: 999;
        }

        .product-info-main {
            padding-bottom: 1rem;

            .page-title-wrapper {
                .page-title {
                    font-size: 1.063rem;
                    line-height: 1.375rem;
                    font-weight: $font-weight-medium;
                    max-width: calc(100% - 30px);
                    margin-bottom: 1.25rem;
                    text-transform: none;
                    letter-spacing: normal;
                }
            }

            .product-info-price {
                margin-bottom: 1rem;

                .price-box {
                    .price-label {
                        font-size: 1.5rem;
                    }

                    .normal-price {
                        margin-right: 0.25rem;

                        .price-excluding-tax {
                            margin-left: 0.25rem;
                        }

                        .price-container.price-final_price {
                            margin-bottom: 1.563rem;
                        }
                    }

                    .price-label {
                        font-size: 1rem;
                        font-weight: 300;
                    }
                }

                .price-wrapper {
                    .price {
                        font-size: 1.063rem;
                        font-weight: 300;
                    }
                }
            }

            .box-tocart {
                .form-group {
                    &.qty {
                        opacity: 0;
                        visibility: hidden;
                        height: 0;
                        width: 0;
                        margin: 0;
                    }

                    &.actions {
                        width: 100%;
                        margin-top: 0;

                        .action {
                            &.tocart {
                                margin: 0 auto;
                                width: 100%;
                                line-height: 1.5rem;
                                max-width: 21.875rem;
                                display: block;
                                @include button-black();

                                &:before {
                                    margin-bottom: 3px;
                                    vertical-align: middle;
                                    @include svg-icon(1rem, 1rem, $icon-shopping-bag-hover, $white);
                                }
                                &:focus,
                                &:hover {
                                    &:before {
                                        font-weight: $font-weight-bold;
                                        @include svg-icon(1rem, 1rem, $icon-shopping-bag, $white);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-options-wrapper {
            .swatch-opt {
                .swatch-attribute-label {
                    text-transform: uppercase;
                    font-weight: $font-weight-light;
                    font-size: 0.813rem;
                }

                .swatch-attribute-options {
                    margin-bottom: 0.625rem;
                }
            }
        }

        .details {
            margin-top: 2rem;

            .product-attribute:not(.usp-attribute) {
                margin-bottom: 0;
                border-bottom: 2px solid #f5f5f5;

                &:first-child {
                    border-top: 2px solid #f5f5f5;
                }

                &.description {
                    .attribute_label {
                        &:after {
                            padding-right: 0;
                        }
                    }
                }

                .attribute_label {
                    display: flex !important;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-weight: $font-weight-light;
                    font-size: 0.875rem;
                    line-height: 3.125rem;

                    &:after {
                        @include svg-icon(0.875rem, 0.875rem, $icon-chevron-up, $primary);
                    }

                    &.collapsed {
                        &:after {
                            @include svg-icon(0.875rem, 0.875rem, $icon-chevron-down, $primary);
                        }
                    }
                }

                .attribute_value {
                    margin-top: 0.5rem;
                    margin-bottom: 1rem;
                    max-height: 18rem;
                    overflow: hidden;
                    font-size: 0.813rem;
                    font-weight: $font-weight-light;
                    line-height: 1.3;

                    p,
                    span {
                        line-height: 1.3;
                    }

                    &.show {
                        overflow: auto;
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        .product-attribute-usp {
            .usp-attribute {
                &:before {
                    width: 1rem;

                    @include media-breakpoint-up($main-breakpoint) {
                        margin-left: -1.3rem;
                    }
                }
                &:last-child,
                &:first-child {
                    margin-bottom: 0;
                }
            }
        }

        .fotorama__stage__frame:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(68, 68, 68, 0.03);
        }

        @include media-breakpoint-up(md) {
            .fotorama-item.fotorama {
                .fotorama__nav-wrap {
                    display: block !important;
                }
            }

            .product-media {
                .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) {
                    .fotorama {
                        &__fullscreen-icon {
                            display: block !important;
                            background: none;
                            top: auto;
                            bottom: 0;
                            width: 50px;
                            height: 50px;

                            &:hover {
                                &::before {
                                    background-color: black;
                                }
                            }
                            &:before {
                                @include svg-mask($icon-fullscreen, 2rem, 2rem, $white);
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: $white;
                                transition: all .3s;
                                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                                line-height: 2rem;
                                border-radius: 50%;
                            }

                            &:hover {
                                background-color: transparent;

                                &:before {
                                    color: $black;
                                }
                            }
                        }
                    }
                }

                .fotorama {
                    overflow: visible;

                    &__stage {
                        &:before,
                        &:after {
                            display: none;
                        }

                        &__shaft {
                            transform: none !important;
                        }

                        &__frame {
                            .fotorama__img {
                                width: 100%;
                            }
                        }
                    }
                }

                .fotorama__nav.fotorama__nav--thumbs {
                    width: auto !important;
                    height: auto !important;
                    transform: none;
                    overflow: visible;
                    white-space: initial;

                    .fotorama__nav__shaft {
                        transform: none !important;
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }

                    .fotorama__thumb__arr {
                        display: none !important;
                    }

                    .fotorama__nav__frame {
                        width: calc(50% - 1rem) !important;
                        height: auto;
                        margin: 1rem 0 !important;
                        padding: 0;
                        box-sizing: border-box;
                        cursor: initial;

                        &::nth-child(odd) {
                            margin-right: 2rem !important;
                        }

                        &:first-child {
                            display: none;
                        }

                        &.fotorama__active {
                            display: none;
                        }
                    }

                    .fotorama__thumb {
                        overflow: visible;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            background-color: rgba(68, 68, 68, 0.03);
                        }
                    }

                    .fotorama__thumb-border {
                        display: none;
                    }

                    .fotorama_vertical_ratio,
                    .fotorama_horizontal_ratio {
                        .fotorama__img {
                            position: static;
                            transform: none;
                        }
                    }
                }

                .fotorama__video-close {
                    display: none;
                }
            }
        }
    }

    .fotorama--fullscreen {
        .fotorama__nav__frame {
            .fotorama__fullscreen-icon {
                display: none !important;
            }
        }

        .fotorama__stage {
            margin-bottom: 0;
            padding-bottom: 1rem;
        }
    }

    .video-howto {
        margin-bottom: 3rem;

        .how-to-use-wrapper {
            padding: 0;

            .how-to-use-container {
                background-color: $gray-200;
            }

            .video-container,
            .how-to-use-container {
                padding: 0;

                .howto {
                    width: 100%;

                    @include media-breakpoint-down($main-breakpoint) {
                        padding: 2rem 1rem;
                    }

                    .attribute-value,
                    .attribute-label {
                        text-align: center;
                    }
                    .attribute-label {
                        font-size: 1.875rem;
                        line-height: normal;
                    }
                    .attribute-value {
                        padding: 0 2rem;
                    }
                }
                .embed-responsive-16by9 {
                    &:before {
                        padding-top: 178%;
                    }
                }
            }
        }
    }

    .page-bottom {
        border-top: 1px solid $gray-b2;

        .crosssell-related-upsell-wrapper {
            background: $white;
            padding-bottom: 2rem;

            .block-title {
                text-align: center;
            }

            .block {
                &.upsell,
                &.related {
                    .product-item-photo {
                        .product-image-container {
                            &:after {
                                background-color: rgba(68, 68, 68, 0.03);
                            }
                        }
                    }
                }
            }
        }
    }
}
