.product-options-wrapper {
    .swatch-attribute {
        &.productcolor {
            padding-right: 0;
        }

        .swatch-option {
            &.text {
                border-radius: 1.563rem;
                padding: 1.3rem 0.5rem;
                min-width: 2.813rem;
            }
        }
    }
}
