.search-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &.active {
        opacity: 1;
        pointer-events: auto;
    }
}

@import "extend/mirasvit";