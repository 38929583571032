.navbar {
    .navbar-nav {
        .nav-link {
            @include media-breakpoint-down($main-breakpoint) {
                display: inline-block;
            }
        }

        .nav-item {
            &.dropdown {

                &.yamm-fw {
                    position: static;

                    @include media-breakpoint-down($main-breakpoint) {
                        position: relative;
                    }

                    .dropdown-menu {
                        width: 100%;
                        margin-top: -1px;
                        border: 0;

                        @include media-breakpoint-down($main-breakpoint) {
                            padding-left: 0;
                        }
                    }
                }

                .dropdown-toggle {
                    display: none;

                    @include media-breakpoint-down($main-breakpoint) {
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 15;
                        padding: .8rem 1.1rem;
                        padding-right: 0;
                        color: $black;
                        cursor: pointer;

                        &::after {
                            @include svg-icon(1rem, 1rem, $icon-circle-plus, $primary);
                        }
                    }
                }

                &.show {
                    .dropdown-toggle::after {
                        @include svg-icon(1rem, 1rem, $icon-circle-minus, $primary);
                    }
                }
            }
        }

        .nav-link {
            padding-right: 1rem;
            padding-left: 1rem;
        }

        .dropdown-menu {
            left: 0;
            right: 0;
            width: (map-get($container-max-widths, xl) - $grid-gutter-width);
            padding: $spacer;

            .row {
                width: 100%;
            }

            a {
                color: $primary;
            }

            .title {
                margin-bottom: map-get($spacers, 2);
                font-weight: $font-weight-bolder;
            }

            ul {
                padding-left: 0;
                margin-bottom: map-get($spacers, 3);
                list-style-type: none;

                li {
                    position: relative;
                    padding-left: 0;
                }
            }
        }
    }
}
