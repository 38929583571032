.form.contact {
    .action.submit.primary {
        @include button-black();
        &:hover {
            & span:before {
                font-weight: $font-weight-bold ;
            }
        }
    }
}
