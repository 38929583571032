.block {
    &.newsletter {
        width: 75%;
        margin: 0 auto;
        padding: $padding-y 0 0;

        .block {
            &-title {
                @include heading($h1-font-size);
                    display: block;
                    margin-bottom: 0.5rem;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    text-align: center;
            }

            &-content {
                > p {
                    text-align: center;
                }
            }
        }

        .field {
            &.newsletter {
                .input-group {
                    .form-control {
                        height: $input-height-lg;
                        padding-left: 20px;
                        box-shadow: none;
                        border-radius: $border-radius-lg 0 0 $border-radius-lg;
                        border-color: #000;
                        border-width: 1px;

                        &:focus {
                            outline: none;
                        }
                    }

                    #newsletter-error {
                        position: absolute;
                        top: 3.5em;
                        width: 100%;
                        padding: map-get($spacers, 1);
                        background-color: #fff;
                        text-align: center;
                    }

                    .subscribe {
                        @include media-breakpoint-down($main-breakpoint) {
                            padding: 0.5rem 1rem;
                        }
                    }
                }
            }
        }
    }

    &.subscribe-note {
        padding: 0 0 $padding-y;

        .info {
            font-size: $font-size-sm;
            color: $gray-300;
            text-align: center;
        }
    }
}
