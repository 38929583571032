.hero {
    position: relative;

    &.hero-banner {
        margin-bottom: map-get($spacers, 6);

        .hero-banner-header {
            &.align-text-center {
                text-align: center;
            }

            &.align-text-right {
                text-align: right;
            }
        }

        .top-text {
            font-size: 2rem;
            text-shadow: 0 0.025rem rgba(0, 0, 0, 0.45);
            text-transform: uppercase;

            @include media-breakpoint-down($main-breakpoint) {
                font-size: 1.5rem;
            }
        }

        .title {
            font-size: 4rem;
            text-shadow: 0 0.025rem rgba(0, 0, 0, 0.45);
            line-height: 1.1;
            letter-spacing: 1px;
            text-transform: uppercase;
            max-width: 10em;

            @include media-breakpoint-down($main-breakpoint) {
                font-size: 2.25rem;
            }
        }

        .btn {
            margin-top: map-get($spacers, 3);
            font-weight: $font-weight-bold;
        }

        &.align-hero-left {
            left: 0;
        }

        &.align-hero-right {
            right: 0;
            text-align: right;
        }

        &.align-hero-center {
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }

    .hero-background-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: opacity 1s ease, transform 0.6s ease;

        .container {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 50%;
            transform: translate(-50%, 0);
            display: flex;
            align-items: center;
            &.align-hero-center {
                justify-content: center;
            }

            &.align-hero-right {
                justify-content: flex-end;
            }
        }

        .player-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100vw;
                min-height: 110vh;
                min-width: 200vh;
                transform: translate(-50%, -50%);

                // object-fit: cover;
                // object-position: center;
                // width: 100%;
                // top: 50%;
                // -webkit-transform: translateY(-50%);
                // -moz-transform: translateY(-50%);
                // -ms-transform: translateY(-50%);
                // -o-transform: translateY(-50%);
                // transform: translateY(-50%);
                // left: 0;
                // position: absolute;
                // /* height based on 1920x1080 ratio */
                // height: calc(100vw * 0.5625);
            }
        }
    }

    .hero-banner-page {
        color: $white;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    &.hero-type-one {
        @include media-breakpoint-down(sm) {
            background-image: none !important;
            background: $black;
        }
    }

    &.hero-type-one,
    &.hero-type-salon-locator {
        @include media-breakpoint-up($main-breakpoint) {
            height: 100vh;
            max-height: 600px;
        }

        .hero-banner-page {
            padding: 4rem 0;

            @include media-breakpoint-up($main-breakpoint) {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: 0;
            }

            .hero-banner-content {
                background: $black;

                @include media-breakpoint-up(sm) {
                    padding: $padding-y;
                }
            }
        }
    }

    &.hero-type-two {
        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }

        .hero-banner-page {
            position: relative;

            .hero-banner-content {
                margin-top: 2rem;

                @include media-breakpoint-up($main-breakpoint) {
                    padding: 8rem 0;
                }

                &.open {
                    height: auto;
                }
            }
        }

        .readmore-wrapper {
            margin-bottom: 2rem;

            a {
                color: $white;
                text-transform: none;

                .more {
                    display: block;
                }

                .less {
                    display: none;
                }

                &.open {
                    .more {
                        display: none;
                    }

                    .less {
                        display: block;
                    }
                }
            }
        }
    }

    &.hero-type-salon-locator {
        .hero-banner-page {
            display: flex;
            justify-content: flex-end;
            left: 0;
        }

        .hero-banner-content {
            margin-right: 2rem;
            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }
    }

    &.hero-type-one,
    &.hero-type-two,
    &.hero-type-salon-locator {
        .hero-banner-page {
            .hero-banner-content {
                .title {
                    font-size: 1.5rem;
                    font-weight: 700;
                    letter-spacing: 0.1rem;
                    text-shadow: none;
                }

                .text {
                    padding: 1rem 0;
                    line-height: 1.7;
                    text-transform: none;
                    text-shadow: none;
                }
            }
        }
    }
}

.home-categories {
    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-down($main-breakpoint) {
        margin-bottom: 3rem;
    }
}

.home-videos {
    padding: map-get($spacers, 6) 0;
    background: $gray-200;

    .home-block-video {
        .video-image {
            position: relative;
            margin-bottom: map-get($spacers, 2);
            overflow: hidden;
            width: 100%;

            img {
                display: block;
                width: 100%;
                transform: scale(1);
                transform-origin: center center;
                transition: transform 1s cubic-bezier(.17, .97, .77, .9);
            }

            .play-button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $white;
                font-size: 4em;
                transition: opacity 1s cubic-bezier(.17, .97, .77, .9);
            }
        }

        &:hover {
            @include media-breakpoint-up($main-breakpoint) {
                text-decoration: none;
                cursor: pointer;

                img {
                    transform: scale(1.1);
                }

                .play-button {
                    opacity: 0.6;
                }
            }
        }

        .video-title {
            display: inline-block;
            font-size: $h4-font-size;
            font-weight: $font-weight-bold;
            letter-spacing: 0.1rem;
            transition: border 0.3s ease;

            span {
                display: block;
                font-size: $font-size-sm;
                text-transform: uppercase;
                font-weight: normal;
                letter-spacing: 0;
            }

            @include media-breakpoint-down($main-breakpoint) {
                margin-bottom: 2rem;
            }
        }
    }
}

.home-product-list {
    padding: map-get($spacers, 6) 0;
    background: $gray-200;

    .block-title {
        padding-bottom: 1rem;
    }

    @include media-breakpoint-down($main-breakpoint) {
        .product-item {
            margin-bottom: 1rem;
        }

        .products-grid .product-item .actions-primary {
            display: none;
        }
    }
}

.home-videos,
.home-product-list {
    .block-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: map-get($spacers, 4);
        letter-spacing: 1px;

        .title {
            font-size: $h1-font-size;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        @include media-breakpoint-down($main-breakpoint) {
            padding-bottom: 2.5rem;
        }
    }
}

.cms-index-index {
    .page-title-wrapper {
        display: none;
    }
}
