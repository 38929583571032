.catalog-product-view {
    .product-media {
        margin-bottom: 4rem;

        @include media-breakpoint-down($main-breakpoint) {
            margin-bottom: 0;
        }
    }
}

.fotorama {
	&.fotorama--fullscreen {
		z-index: 3000 !important;
	}
	.fotorama__stage {
		margin-bottom: 1em;
		overflow: hidden;
	}
	.fotorama__arr {
        background: none !important;

        &:hover, &:focus {
            background: none !important;
        }
    }
	.fotorama__arr--prev .fotorama__arr__arr,
    .fotorama__arr--next .fotorama__arr__arr {
        background:none;
    }

    .fotorama__arr--next {
        &:before {
            right: 1em;
        }
    }

    .fotorama__arr--prev {
        &:before {
            left: 1em;
        }
    }
    .fotorama__arr--prev, .fotorama__arr--next {
        .fotorama_arr_arr {
            background:none;  
        }
        &:before {
            position: absolute;
            display: block;
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
            height: 40px;
            border-radius: 24px;
            color: $primary;
            background: #fff;
            font-size: 1rem;
            z-index: 99999 !important;	
            line-height: 40px;	
            text-align: center;
        }
    }
    .fotorama__arr--prev  {  
        &:before {
            @include svg-icon(1rem, 1rem, $icon-angle-left, $primary);
        }
    }
    .fotorama__arr--next {
        &:before {
            @include svg-icon(1rem, 1rem, $icon-angle-right, $primary);
        }
    }
	.fotorama__nav-wrap {
		@include media-breakpoint-down($main-breakpoint){
			display: none !important;
		}
	}
	.fotorama__thumb-border {
		border-color: transparent;
	}
	.fotorama__thumb {
		overflow: hidden;
	}
}

.product-info-main {
    .page-title {
        margin-bottom: $spacer;
        font-size: $h1-font-size;
        letter-spacing: 1px;
    }

    .price-box {
        font-size: $display4-size;
        font-weight: $font-weight-bold;


        .old-price {
            color: $gray-500;
            font-size: $h2-font-size;
            font-weight: $font-weight-light;
            text-decoration: line-through;

            &.sly-old-price {
                display: inline-block;

                .price-label {
                    display: none;
                }
            }

        }
        .price-excluding-tax {
            margin-left: 0.5rem;
            font-size: $h3-font-size;
            font-weight: $font-weight-medium;

            &:after {
                content: ' ' attr(data-label);
                text-transform: lowercase;
                font-size: $h4-font-size;
                font-weight: $font-weight-normal;
            }
        }
    }

    .mage-error {
        color: $danger;
    }
}

.product-attribute,
.additional-attributes {
    .label {
        font-weight: $font-weight-bold;
    }

    &.overview,
    &.howto,
    &.contains {
        margin-bottom: $margin-y;
        
        .label {
            position: relative;
            display: block;
            width: 100%;
            padding: $spacer;
            margin-bottom: $spacer;
            background: $gray-200;
            font-weight: $font-weight-bold;
        }
        
        @include media-breakpoint-up($main-breakpoint) {
            .value {
                display: block;
                padding: 0 map-get($spacers, 2);
            }
        }
    }

    &.description,
    &.title {
        .label {
            @include heading($h2-font-size);
        }
    }
}

.box-tocart {
    .spinbox {
        display: block;
        margin-right: map-get($spacers, 3);

        label {
            display: block;
            margin-right: map-get($spacers, 3);
            margin-bottom: 0.5rem;
        }

        .spinbox-input {
            width: 4rem;
            text-align: center;
            border-color: transparent;
        }
    }

    .form-group.actions {
        margin-top: 2rem;
        align-items: flex-start;
    }

    .action {
        &.tocart {
            margin-left: map-get($spacers, 6);

            &:focus,
            &:hover {
                &::before {
                    @include svg-icon(1rem, 1rem, $icon-shopping-bag-hover, $body-color);
                }
            }

            &::before {
                margin-right: map-get($spacers, 2);
                @include svg-icon(1rem, 1rem, $icon-shopping-bag-hover, $white);
                vertical-align: sub;
            }

            @include media-breakpoint-down($main-breakpoint) {
                margin-left: 0;
            }
        }

        &.towishlist {
            &::before {
                @include svg-icon(1.5rem, 1.5rem, $icon-heart-hover, $primary);
            }
        }
    }
}

.product-addto-links {
    margin-top: 0;

    .action {
        &.towishlist {
            span {
                display: none;
            }

            &:before {
                margin-right: 0;
                font-size: $h1-font-size;
                font-weight: $font-weight-light;
            }

            &:hover {
                background-color: transparent;
                border-color: transparent;

                &:before {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}

.wholesale-usp {
    .block-usp {
        margin-bottom: 4em;

        @include media-breakpoint-down($main-breakpoint) {
            margin-top: 1rem;
        }
    }
}

.product-attribute-usp {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;

    .usp-attribute {
        display: list-item;
        padding-left: 1.4rem;

        &::before {
            @include svg-icon(1rem, 1rem, $icon-check, $primary);
            vertical-align: text-top;
            display: inline-block;
            margin-left: -1.4rem;

            @include media-breakpoint-up($main-breakpoint) {
                margin-left: -1rem;
            }

        }

        @include media-breakpoint-up($main-breakpoint) {
            .value {
                display: inline;
                padding: 0;
            }
        }
        &:first-child {
            margin-top: $spacer;
        }
        &:last-child {
            margin-bottom: $margin-y;
        }
    }
}

.attribute-collapse {
    .label {
        font-family: $headings-font-family;
        font-size: $font-size-base;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
    }

    .label-collapse {
        &:after {
            float: right;
            @include svg-icon(1rem, 1rem, $icon-minus, $primary);
        }

        &.collapsed {
            &:after {
                @include svg-icon(1rem, 1rem, $icon-plus, $primary);
            }
        }
    }
    &.description {
        .label-collapse {
            &:after {
                padding-right: 0.9rem;
            }
        }
    }
}

.additional-attributes {
    dl {
        @include make-col-ready();

        .item {
            @include make-row();
        }

        dt {
            @include make-col(3);
        }

        dd {
            margin: 0;
            @include make-col(9);
        }
    }
}

.product-details-wrapper {
    padding: map-get($spacers, 6) 0;
}

.product-options-bottom {
    margin-top: $spacer;
}

.howto {
    .scrollto {
        @include hover-underline(0.5rem);

        margin: map-get($spacers, 2);
        margin-top: -1rem;
        font-size: $font-size-base;

        i {
            margin-left: map-get($spacers, 2);
            line-height: $btn-line-height;
        }
        &.btn-link {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.product-details-wrapper {
    .additional-attributes {
        margin-top: 1rem;

        &.title {
            margin-top: 2rem;
        }
    }
}
@include media-breakpoint-down($main-breakpoint) {

    .product-social-links,
    .product-addto-links {
        display: inline;
    }

    .product-details-wrapper {
        .additional-attributes {
            margin-top: 1rem;
    
            &.title {
                margin-top: 2rem;
            }
        }
    }

    .catalog-product-view {
        .products-grid {
            .product-item-actions {
                display: none;
            }
        }
    }
}
