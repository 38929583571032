/* LEGAL DISCLAIMER
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.balmainhair.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Optimo webfonts are protected by copyright law and provided under license. To modify, alter, translate, convert, decode or reverse engineer in any manner whatsoever, including converting the Font Software into a different format is strictly prohibited. The webfont files are not to be used for anything other than web font use.
 *
 * optimo.ch
 */

/* INSTRUCTIONS
 *
 * Copy the Legal Disclaimer, the domains name and the @font-faces statements to your regular CSS file. The fonts folder(s) should be placed relative to the regular CSS file.
 */

$couture-font-path: "../fonts/" !default;

@font-face {
    font-family: 'Plain';
    src: url('#{$couture-font-path:}/Plain/Plain-Thin.eot');
    src: url('#{$couture-font-path:}/Plain/Plain-Thin.eot?#iefix') format('embedded-opentype'),
         url('#{$couture-font-path:}/Plain/Plain-Thin.woff2') format('woff2'),
         url('#{$couture-font-path:}/Plain/Plain-Thin.woff') format('woff');
    font-weight: 250;
    font-style: normal;
}

@font-face {
    font-family: 'Plain';
    src: url('#{$couture-font-path:}/Plain/Plain-Regular.eot');
    src: url('#{$couture-font-path:}/Plain/Plain-Regular.eot?#iefix') format('embedded-opentype'),
         url('#{$couture-font-path:}/Plain/Plain-Regular.woff2') format('woff2'),
         url('#{$couture-font-path:}/Plain/Plain-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Plain';
    src: url('#{$couture-font-path:}/Plain/Plain-Italic.eot');
    src: url('#{$couture-font-path:}/Plain/Plain-Italic.eot?#iefix') format('embedded-opentype'),
         url('#{$couture-font-path:}/Plain/Plain-Italic.woff2') format('woff2'),
         url('#{$couture-font-path:}/Plain/Plain-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Plain';
    src: url('#{$couture-font-path:}/Plain/Plain-Medium.eot');
    src: url('#{$couture-font-path:}/Plain/Plain-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$couture-font-path:}/Plain/Plain-Medium.woff2') format('woff2'),
    url('#{$couture-font-path:}/Plain/Plain-Medium.woff') format('woff');
    font-weight: 500;
    font-style: medium;
}

@font-face {
    font-family: 'Plain';
    src: url('#{$couture-font-path:}/Plain/Plain-Bold.eot');
    src: url('#{$couture-font-path:}/Plain/Plain-Bold.eot?#iefix') format('embedded-opentype'),
         url('#{$couture-font-path:}/Plain/Plain-Bold.woff2') format('woff2'),
         url('#{$couture-font-path:}/Plain/Plain-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
