.block-usp {
    list-style: none;
    margin-bottom: 0.5rem;
    padding-left: 0;

    li {
        display: flex;
        align-items: center;

        .link {
            .title {
                @include hover-underline(0rem);
            }
        }

        a {
            display: flex;
            align-items: center;
            text-decoration: none;

            &:hover {
                .title {
                    text-decoration: none;
                }
            }
        }


        $icons: (
            truck: $icon-truck,
            undo: $icon-undo,
        );

        $iconWidthDesktop: 1.1rem;

        @each $class,
        $icon in $icons {

        .usp-col {
            .icon {
                .fa,
                .icon {
                    &-#{$class} {
                        &::before {
                            @include svg-icon($iconWidthDesktop, $iconWidthDesktop, $icon, $primary);
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }


    .usp-col {
        display: inline-block;
        vertical-align: middle;

        .title {
            @include media-breakpoint-down($main-breakpoint) {
                font-size: $font-size-sm;
            }
        }

        +.usp-col {
            padding-left: 0.5rem;
        }
    }
}
}

.product-usp {
    margin-top: $spacer;
    padding: 1.5rem 0;

    @include media-breakpoint-down(sm) {

        .usp-item {
            + .usp-item {
                margin-top: 0.5rem;
            }
        }
    }
    
    .block-usp {
        a {                
            text-decoration: none;

            &:hover {
                .title {
                    text-decoration: none;
                }
            }
        }
        .usp-col {
            .icon {
                display: inline-block;
            }

            +.usp-col {
                padding-left: 0.5rem;
            }
        }
    }
}
.wholesale-usp {
    li {
        a {                
            text-decoration: none;
    
            &:hover {
                .title, .description {
                    text-decoration: none;
                }
            }
        }
        .usp-col {
            .icon {
                .icon-info-circle {
                    @include svg-icon(2rem, 2rem, $icon-info-circle, $primary);
                    
                    @include media-breakpoint-down($main-breakpoint) {
                        @include svg-icon(1rem, 1rem, $icon-info-circle, $primary);
                    }
                    
                    &::before {
                        display: none;
                    }
                }
            }
        }
        
        .description {
            font-weight: $font-weight-bold;
            text-decoration: underline;
        }
    }
}