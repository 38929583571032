.customer-account-login, 
.customer-account-forgotpassword, 
.customer-account-create {
    .page-title {
        margin-top: 0;
        margin-bottom: $margin-y;
        padding-top: $padding-y;
        text-align: center;
        text-transform: uppercase;
    }
}
.customer-container-wrapper {
    background-color: $gray-200;

    .form-control {
        border-width: 0;
    }
}

.customer-account-login {
    .login-container {
        padding-top: $padding-y;

        .form-control {
            border-width: 0;
        }
    }
    .block-customer-login, .block-new-customer {
        margin-bottom: $margin-y;
        padding-top: 0.5rem;

        .block-title {
            @include heading($h2-font-size);
        }
    }
    .block-customer-login {
        padding-right: $grid-gutter-width / 4;
        
        @include media-breakpoint-only(md) {
            padding-right: $grid-gutter-width / 2;
        }

        @include media-breakpoint-up(md) {
            border-right: 1px solid $border-color;
        }
        @include media-breakpoint-up(lg) {
            padding-right: 4rem;
        }
        .primary {
            &:hover {
                span {
                    &::before {
                        @include svg-icon(1rem, 1rem, $icon-sign-in, $primary);
                    }
                }
            }

            span {
                &:before {
                    margin-right: map-get($spacers, 2);
                    margin-bottom: map-get($spacers, 1);
                    vertical-align: middle;
                    @include svg-icon(1rem, 1rem, $icon-sign-in, $white);
                }
            }
            }   
        }

    .block-new-customer {
        padding-left: $grid-gutter-width / 4;

        @include media-breakpoint-only(md) {
            padding-left: $grid-gutter-width / 2;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 4rem;
        }
        .primary {
            &:hover {
                span {
                    &::before {
                        @include svg-icon(1rem, 1rem, $icon-sign-up, $primary);
                    }
                }
            }

            span {
                &:before {
                    margin-right: map-get($spacers, 2);
                    margin-bottom: map-get($spacers, 1);
                    vertical-align: middle;
                    @include svg-icon(1rem, 1rem, $icon-sign-up, $white);
                }
            }
        }
    }
    .actions-toolbar {
        > .primary {
            .primary {
               
            }
        }
    }
}
.customer-account-create {
    .amgdpr-checkbox {
        &.choice {
            a {
                text-decoration: underline;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }
        }
        &.required {
            label {
                &:after {
                    content: "*";
                    color: $red;
                }
            }
        }
        div.mage-error {
            color: $danger;
        }
    }   
}

.account-nav {
    .items {
        .item {
            position: relative;
            width: 100%;
        }
    }
}