.home-product-list {
    background: $white;
    padding-top: 0;

    .block-title {
        align-items: flex-start;
        .title {
            @include block-title();
            letter-spacing: 0.281rem;
        }
    }

    .block-title {
        padding-bottom: 0;
    }

    .products-grid {
        .product-col {
            padding: 0  0.63rem;
        }

        .product-item-info {
            .product-item-details {
                padding-top: 0;
                margin: 0 0.188rem;
            }
        }
    }
}

.hero {
    &.hero-banner:not(.video)  {
        height: calc(100vh - 72px);
        max-height: inherit;

        @include media-breakpoint-down(sm) {
            height: calc(100vh - 56px);
        }
    }
    &.hero-banner.video {
        width: 100vw;
        height: 700px;

        @include media-breakpoint-down($main-breakpoint) {
            height: 820px;
        }
    }
    &.hero-type-salon-locator {
        margin-bottom: 3rem;
        background-position: bottom;
        .hero-banner-page {
            @include media-breakpoint-down(sm) {
                padding: 9rem 0 3rem;
            }

            .hero-banner-content {
                a {
                    .title, .text {
                        color: $white;
                    }
                }

                @include media-breakpoint-down(sm) {
                    background: none;
                    padding: 0;

                    .title {
                        margin-bottom: 0.5rem;
                    }

                    .text {
                        display: none;
                    }
                }
            }
        }
    }

    .hero-banner-header,
    .hero-banner-page {
        line-height: 2.625rem;

        .top-text {
            font-weight: $font-weight-bold;
        }
        .title {
            font-size: 2.5rem;
        }

        .btn.btn-primary {
            @include button-styles(calc(100% - 10px));
        }
    }

    @include media-breakpoint-down(sm) {
        .hero-banner-header,
        .hero-banner-page {
            font-size: 1.625rem;
        }
    }
}

.home-categories {
    .item.big-block {
        &:hover {
            .block-content {
                .btn.btn-primary.btn-white {
                    font-weight: $font-weight-bold;
                    box-shadow: none;
                }

                @include media-breakpoint-down(sm) {
                    .btn.btn-primary.btn-white {
                        font-size: 0.813rem;
                    }
                }
            }
        }

        .btn.btn-primary {
            @include button-styles(15rem);
        }

        .product-name {
            font-size: 2.5rem;
        }
    }

    .categories-grid_title {
        font-size: 0.9rem;
        font-weight: $font-weight-medium;
        letter-spacing: 0.28rem;
    }
}