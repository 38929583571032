.amlocator-main-container {
    .amlocator-map-container {
        .amlocator-search-container {
            .amlocator-button.-nearby {
                border: none;
                background-color: $black;
                color: $white;

                @include button-black();
            }

            .amlocator-block {
                label {
                    font-size: 0.75rem;
                }
            }
        }
        .amlocator-block.-filter {
            .amlocator-title {
                text-transform: uppercase;
            }

            .amlocator-label {
                font-size: 0.875rem;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
            }

            .amlocator-input {
                .chosen-container {
                    font-size: 1rem;
                }
            }
        }

        .amlocator-store-list {
            .amlocator-title {
                text-transform: uppercase;
                font-size: 0.875rem;
            }
            .amlocator-store-desc {
                font-size: .75rem;
            }
        }
    }
}
