.authentication-wrapper {
    .action.action-auth-toggle {
        @include button-black();
        min-width: 7rem;
    }
}

.opc-sidebar {
    .opc-block-summary {
        .title {
            @include title();
        }
    }
}

.checkout-shipping-address {
    .step-title {
        @include title();
    }
}

.form-shipping-address,
.form-login {
    font-size: 0.813rem;
    .mage-error {
        margin-top: 0.313rem;
    }
}

.field._required._error {
    .field-error {
        position: relative;
        top: 0;
        margin-top: 0.313rem;
    }
}

.product-item-details {
    .product-item-name-block {
        .product-item-name {
            font-size: 0.813rem;
            line-height: 1rem;
        }
    }

    .details-qty {
        margin-top: 1.25rem;
        text-transform: uppercase;
    }
}

.table-totals {
    font-size: 0.813rem;

    .mark {
        text-transform: uppercase;
    }

    .price {
        font-weight: $font-weight-bold;
    }

    .grand.totals {
        .price {
            font-size: 1rem;
        }
    }
}

.actions-toolbar {
    .primary {
        .button.action.continue,
        .action.primary.checkout {
            @include button-black();
            min-width: 7rem;
        }
    }
}

.checkout-payment-method {
    .payment-option,
    .step-title,
    .label {
        text-transform: uppercase;
    }
}

.form-discount {
    .action.action-apply {
        @include button-black();
    }
}

#block block-new-customer,
#block-customer-login-heading {
    text-transform: uppercase;
}

.action.action-guest.btn.btn-cta,
.action.action-login.secondary {
    @include button-black();
}

.block-content {
    .actions-toolbar {
        .primary {
            .action.action-login.secondary {
                min-width: 10rem;

                &:hover {
                    &:before {
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }
    }
}

.checkout-index-index {
    .page-header {
        position: relative !important;
    }
}