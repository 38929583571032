header {
    z-index: 1001;
    .header {
        .btn {
            .icon {
                &:before {
                    font-weight: 600;
                }
            }
            &:hover {
                .icon {
                    &:before {
                        font-weight: $font-weight-normal;
                    }
                }
            }
        }

        .logo {
            img {
                height: auto;
                width: 100%;
                height: 56px;
                max-width: 110px;
                @include media-breakpoint-down(xs) {
                    height: 56px;
                    max-width: 75px;
                }
            }
        }
        .search-toggle {
            &:hover {
                .icon {
                    @include svg-icon(1.3125rem, 1.3125rem, $icon-search-hover, $white);
                }
            }
            .icon {
                @include svg-icon(1.3125rem, 1.3125rem, $icon-search, $white);
            }
        }
        .header {
            &-account {
                &:hover {
                    .icon {
                        @include svg-icon(1.3125rem, 1.3125rem, $icon-account-hover, $white);
                    }
                }
                .icon {
                    @include svg-icon(1.3125rem, 1.3125rem, $icon-account, $white);
                }
            }
        }

        .row {
            min-height: 56px;
        }
    }
    .navbar-toggler {
        color: $white;

        .navbar-toggler-icon {
            background-image: escape-svg($navbar-dark-toggler-icon-bg);
        }
    }
    .header {
        padding: map-get($spacers, 2) 0;
        @include media-breakpoint-down($main-breakpoint) {
            display: block;
        }

        @include media-breakpoint-down(xs) {
            padding: 0;

            .btn,
            button {
                padding: 0.375rem 0.3rem;
                &.header-minicart {
                    padding-right: 0;
                }
            }
        }

        @include media-breakpoint-down(xs) {
            .btn,
            button {
                padding: 0.375rem 0.2rem;
            }
        }

        .btn {
            .icon {
                font-size: $h2-font-size;
            }
        }
    }

    .navbar-toggler {
        width: 21px;
        height: 18px;
        position: relative;
        padding: 0;
        margin-right: 0.75rem;
        border: none;
        border-radius: initial;
        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: $white;
            opacity: 1;
            left: 0;
            transition: .25s ease-in-out;
            &:nth-child(1) {
                top: 0;
                transform-origin: left center;
            }

            &:nth-child(2) {
                top: 8px;
                transform-origin: right center;
            }

            &:nth-child(3) {
                top: 16px;
                transform-origin: left center;
            }
        }

        &[aria-expanded="true"] {
            span {
                &:nth-child(1) {
                    transform: rotate(45deg);
                    left: 4px;
                }

                &:nth-child(2) {
                    width: 0;
                    left: 100%;
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                    top: 15px;
                    left: 4px;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    header {
        .navbar-toggler {
            margin-right: 0;
        }
    }
}
