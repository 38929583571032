.products-grid {
    position: relative;

    .product-col {
        padding: 0 0.75rem 0.75rem;
        display: flex;
        border: 1px solid $white;
        border-top: 0;
        transition: .2s ease all;
        background: $white;
        position: relative;
        margin-bottom: 5rem;
        min-height: 25rem;
        @include media-breakpoint-down(sm) {
            min-height: 15.625rem;
        }

        &:hover {
            text-decoration: none;
            .product-item-link {
                text-decoration: underline;
            }
        }

        &.category-image-col {
            overflow: hidden;
            padding: 0;
            border: 0.813rem solid $white;
            border-top: 0;

            .category-image {
                position: absolute;
                height: 100%;
                max-width: initial;
                top: 0;
                left: 50%;
                transform: translate(-50%);
            }
        }
    }

    .product-item {
        margin-bottom: 0;
        display: flex;
        text-align: left;
        width: 100%;
    }

    .product-item-info {
        padding: 0;
        width: 100%;
        .product-item-photo {
            margin-bottom: 0;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        &:before,
        &:after {
            display: none;
        }

        .product-item-link {
            font-weight: $font-weight-bold;
            font-size: 0.813rem;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .product-item-photo {
            .product-image-container {
                margin: 0 auto;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(68,68,68,.03);
                }
            }
        }

        .product-item-details {
            position: absolute;
            top: 100%;
            width: 100%;
            margin: 0.5rem 0.188rem 0;

            .product-item-name {
                margin-bottom: 0;
                min-height: auto;
                text-align: left;
            }

            .price-box {
                text-align: left;
                margin-bottom: 0;

                .price-container {
                    font-weight: 300;

                    .price-excluding-tax {
                        display: none;
                    }
                }

                .old-price {
                    text-decoration: line-through;
                }
            }
        }

        .product-labels {
            font-size: 0;

            .product-label {
                position: static;
                padding: 0;
                margin: 0 0 0.1rem;
                border: 0;
                text-transform: uppercase;
                display: inline-block;
                font-weight: 300;
                + .product-label {
                    &:before {
                        content: ',';
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.pages-items.pagination {
    margin-bottom: 0;
    .page-link {
        padding: 0.8rem 0.3rem;
        &:not(.jump) {
            min-width: 2.75rem;
            text-align: center;
        }
    }

    .jump {
        &:hover {
            text-decoration: underline;
        }
    }

    @include media-breakpoint-down(xs) {
        .item {
            + .item {
                margin-left: 0.4rem;
            }
        }

        .page-link {
            padding: 0.3rem;
            &:not(.jump) {
                min-width: 1.75rem;
            }
        }
    }
}

.page-bottom {
    .crosssell-related-upsell-wrapper {
        padding: 2.181rem 0 4rem;
        .block-title.title {
         @include block-title-light();
        }
    }
}

.block {
    &.upsell,
    &.related {
        .product-item-photo {
            .product-image-container {

                &:after {
                    background-color: inherit;
                }
            }
        }
    }

    &.crosssell {
        .products-grid {
            .col-lg-3 {
                @include media-breakpoint-up(lg) {
                    max-width: calc(25% - 1rem);
                }
            }

            .col-md-4 {
                @include media-breakpoint-down(md) {
                    max-width: calc(33.33% - 1rem);
                }
            }

            .col-sm-6 {
                @include media-breakpoint-down(sm) {
                    max-width: calc(50% - 1rem);
                }
            }

            .product-col {
                margin: 0 0.5rem 2rem;

                .product-item-photo {
                    position: relative;
                    min-height: 372px;
                    width: 100%;
                    height: auto;
                    @include media-breakpoint-down(md) {
                        min-height: 250px;
                    }

                    .product-image-container {        
                        &:after {
                            display: none;
                            content: initial;
                        }
                    }
                }

                .product-item-details {
                    position: static;
                }
            }
        }
    }
}
