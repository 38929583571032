@import "module/_base";
@import "module/_amstars";
@import "module/_location";
@import "module/_review-popup";

.amlocator-main-container {
    .amlocator-map-container {
        .amlocator-search-container {
            .amlocator-current-location {
                margin-top: $spacer;
            
                .amlocator-text {
                    @extend .form-control;
                    border: 0;

                    &:focus {
                        + .amlocator-search {
                            filter: grayscale(100%);
                        }
                    }
                }
                .amlocator-search {
                    top: 16px;
                    right: 16px;
                    width: 16px;
                    height: 16px;

                    &:hover {
                        filter: grayscale(100%);
                    }
                }   
            }
            label {
                font-size: $font-size-base;
            }

            .amlocator-button {
                &.-nearby {
                    @include button-outline-variant($btn-primary, $btn-primary);
                    margin-top: $spacer;
                    margin-bottom: $spacer;
                    border-radius: $btn-border-radius-lg;
                    text-transform: uppercase;
                    box-shadow: none;
    
                    &:hover, &:focus {
                        color: $white;
                        background-color: $black;
                    }
                }
            }
        }
    }
    
    .amlocator-range-slider {
        background: $gray-400;
        height: 5px;

        .ui-slider-range {
            background: $black;
        }
        .amlocator-handle {
            background: $gray-700;
            box-shadow: 0 2px 3px rgba(20, 72, 112, 0.5);
            transform: translateY(-50%);

            &:hover,
            &:focus,
            &:active {
                background: $black;

            }
        }
        .amlocator-tooltip {
            background-color: $black;
            
            &:after {
                background: $black;
            }
        }
    }

    .amlocator-store-desc {
        &.-active {
            background: $black;
            
            &:hover {
                background: $black;
            }
        }
        &:hover {
            background: $gray-300;
        }
    }
}

