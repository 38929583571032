.switcher-language {
    margin-left: 1rem;
    &.dropdown {
        .switcher-view,
        .store-list {
            img {
                margin-right: 0.25rem;
                max-height: 24px;
                max-width: 24px;
            }

            .icon {
                @include media-breakpoint-down(md) {
                    display: none;
                }

                @include media-breakpoint-down(sm) {
                    display: inline;
                }
            }
        }
        .dropdown-menu {
            min-width: 16rem;
            margin: 0.75rem 0 0;
            @include media-breakpoint-down(sm) {
                box-shadow: none;
                background: transparent;
                padding: 1rem 1rem 1rem 0;
            }

            ul {
                li {
                    padding: 0.5rem 0;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .page-header {
        .switcher-language {
            display: none;
        }
    }

    .navbar {
        .switcher-language {
            display: block;
            .dropdown-menu {
                @include media-breakpoint-down(md) {
                    position: inherit;
                }
            }
        }
    }
}
