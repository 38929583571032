// Sphinxsearch
$adjust-huener-color-from: #D8D8D8;
$adjust-huener-color-to: #D8D8D8;
$header-bg: #EFEFEF;
$hr-color: #F6F6F6;
$hover-bg: #f8f8f8;
$mute-color: #999;

.mst-searchautocomplete__autocomplete {
    display: none !important;
    position: absolute;
    top: 0em;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    min-height: 3.5rem;
    box-sizing: border-box;
    background: #fff;
    border-top: 1px solid #fff;
    box-shadow: rgba(102, 102, 102, 0.08) 0px 2px 16px 0px;
    text-align: left;
    z-index: 3200;
    overflow: hidden;
    @include border-bottom-radius(0.25rem);

    &._active {
        display: block !important;
    }

    .mst-searchautocomplete__adjust-huener {
        display: none;
        position: absolute;
        top: 5px;
        left: 50%;
        margin-left: -90px;
    }

    &._loading {
        .mst-searchautocomplete__adjust-huener {
            display: block;
        }
    }

    .mst-searchautocomplete__empty-result {
        background: $gray-300;
        text-align: center;
        padding: 8px 10px;
        color: $gray-600;
        font-size: 1.2rem;
        display: block;
    }

    .mst-searchautocomplete__show-all {
        background: $primary;
        text-align: center;
        transition: background-color 0.1s ease;

        &:hover {
            background-color: darken($primary, 10%);
        }

        a {
            display: block;
            padding: 1em 1em;
            color: #fff;

            span {
                font-size: 1em;
            }
        }
    }

    .mst-searchautocomplete__index-title {
        background: $gray-200;
        padding: 0.75rem 1rem;
        text-align: left;
        color: $gray-800;
        text-transform: uppercase;
        font-weight: 700;
    }

    .mst-searchautocomplete__close {
        display: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            @include clearfix();
            margin: 0 1rem;
            padding: 0.5rem 0;
            cursor: pointer;

            a.title {
                font-weight: 400;
                margin-bottom: 0;
                color: $body-color;
                text-decoration: none;
            }

            &._active {
                a.title {
                    text-decoration: underline;
                }
            }
        }
    }
}

.mst-searchautocomplete__index {
    &.popular {
        .index-title {
            background: none;
            text-align: left;
            color: #999;
        }

        ul {
            li {
                padding: .7rem 0 .7rem 1rem;
                margin: 0;

                a {
                    font-size: 1rem;
                    color: #575757;
                }

                &:hover {
                    background: none;
                    cursor: pointer;

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.mst-searchautocomplete__item {
    &.magento_catalog_product {
        display: flex;
        border-bottom: 1px solid $gray-200;
        align-items: flex-start;

        &:last-child {
            border-bottom: none;
        }

        img {
            max-width: 3rem;
            margin-right: 1rem;
        }

        .title {
            display: block;
            margin-bottom: 0.25rem;

            a {
                color: $body-color;
            }

            .sku {
                color: $gray-600;
            }
        }

        .meta {
            flex-grow: 1;
            overflow: hidden;
        }

        .description {
            font-size: 0.75rem;
            color: $gray-600;
            height: 3rem;
            overflow: hidden;
            margin-bottom: 0.5rem;
        }

        .price {
            font-size: $font-size-sm;
            font-weight: 600;
            white-space: nowrap;
        }

        .product-reviews-summary {
            margin: 0;

            .reviews-actions {
                display: none;
            }
        }

        .to-cart {
            float: right;
            margin-top: .5rem;
            margin-bottom: .5rem;
        }

        &._active {
            background: #fff !important;

            .title {
                a {
                    text-decoration: underline;
                }
            }
        }
    }

    &.magento_catalog_category {
        a {
            i {
                color: $gray-600;
                display: inline-block;
                margin: 0 .5rem;
                font-style: normal;
            }
        }
    }

    &.magento_search_query {
        a {
            float: left;
        }

        .num_results {
            float: right;
            color: $gray-600;
        }
    }
}

.mst-searchautocomplete__highlight {
    font-weight: 600;
    color: $warning;
}

.mst-searchautocomplete__adjust-huener {
    position: relative;
    width: 186px;
    height: 20px;
    margin: auto;

    .adjust-huener-item {
        position: absolute;
        top: 0;
        background-color: $adjust-huener-color-from;
        width: 20px;
        height: 20px;
        animation-name: bounce_fountainG;
        animation-duration: 0.645s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        transform: scale(.3);
        border-radius: 15px;

        &.adjust-huener-item-1 {
            left: 0;
            animation-delay: 0.256s;
        }

        &.adjust-huener-item-2 {
            left: 20px;
            animation-delay: 0.3225s;
        }

        &.adjust-huener-item-3 {
            left: 40px;
            animation-delay: 0.389s;
        }

        &.adjust-huener-item-4 {
            left: 60px;
            animation-delay: 0.4555s;
        }

        &.adjust-huener-item-5 {
            left: 80px;
            animation-delay: 0.522s;
        }

        &.adjust-huener-item-6 {
            left: 100px;
            animation-delay: 0.5885s;
        }

        &.adjust-huener-item-7 {
            left: 120px;
            animation-delay: 0.645s;
        }

        &.adjust-huener-item-8 {
            left: 140px;
            animation-delay: 0.7115s;
        }
    }
}

.mst-search__result-tabs {
    display: flex;
    list-style: none;
    border-bottom: 1px solid $gray-200;
    padding: 0;

    li {
        margin: 0 5px -1px 0;
        padding: 0;

        a {
            display: block;
            padding: 10px 35px;
            text-decoration: none;
            color: #7d7d7d;
            background: #f6f6f6;
            border: 1px solid $gray-200;
            border-radius: 1px 1px 0 0;

            &.active,
            &:hover,
            &:focus {
                color: #333;
                background: #fff;
            }

            &.active {
                border-bottom: 1px solid #fff;
            }
        }
    }
}

.mst-search__result-index {
    padding: 1rem 0 1rem 0;

    .mst-search__result-index-title {
        background: #f6f6f6;
        padding: 1rem;
        border-radius: 1px;
        color: #7d7d7d;
    }

    &:last-child {
        margin-bottom: 2.5rem;
        border-bottom: 1px solid #f6f6f6;
    }
}

.mst-search__index {
    margin-bottom: 2rem;

    ul:not(.blog-post-info) {
        list-style: none;
        margin-bottom: 10px;
        padding-left: 0;

        li:not(.blog-post-info-item) {
            margin: 2rem 0;

            &:last-child:not(.blog-post-info-item) {
                margin-bottom: 0;
            }

            .mst-search__index-title {
                font-size: 1.4rem;
                font-weight: 600;

                >a {
                    color: $secondary;
                }

                >span {
                    color: #aaa;
                }
            }

            .mst-search__index-content {
                color: #666;
                margin: .5rem 0 0 0;
            }

            .mst-search__index-image {
                margin-right: 2rem;
                float: left;

                >img {
                    max-width: 5rem;
                    max-height: 5rem;
                }
            }
        }
    }
}

@keyframes bounce_fountainG {
    0% {
        transform: scale(1);
        background-color: $adjust-huener-color-from;
    }

    100% {
        transform: scale(.3);
        background-color: $adjust-huener-color-to;
    }
}

@-o-keyframes bounce_fountainG {
    0% {
        -o-transform: scale(1);
        background-color: $adjust-huener-color-from;
    }

    100% {
        -o-transform: scale(.3);
        background-color: $adjust-huener-color-to;
    }
}

@-ms-keyframes bounce_fountainG {
    0% {
        -ms-transform: scale(1);
        background-color: $adjust-huener-color-from;
    }

    100% {
        -ms-transform: scale(.3);
        background-color: $adjust-huener-color-to;
    }
}

@-webkit-keyframes bounce_fountainG {
    0% {
        -webkit-transform: scale(1);
        background-color: $adjust-huener-color-from;
    }

    100% {
        -webkit-transform: scale(.3);
        background-color: $adjust-huener-color-to;
    }
}

@-moz-keyframes bounce_fountainG {
    0% {
        -moz-transform: scale(1);
        background-color: $adjust-huener-color-from;
    }

    100% {
        -moz-transform: scale(.3);
        background-color: $adjust-huener-color-to;
    }
}