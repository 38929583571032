 .header-minicart {
    &:focus,
    &:hover {
        .icon {
            @include svg-icon(1.3125rem, 1.3125rem, $icon-shopping-bag, $white);
        }
    }
    
    .icon {
        @include svg-icon(1.3125rem, 1.3125rem, $icon-shopping-bag-hover, $white);
    }
    
    &.btn {
        padding-right: 0;
    }
    .counter {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -0.4rem;
        bottom: 0.85rem;
        width: 1rem;
        height: 1rem;
        line-height: 1.2;
        text-align: center;
        color: $primary;
        background: $white;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;

        .counter-number {
            font-size: 0.6rem;
        }

        &.empty {
			display: none;
		}
    }
}
.offcanvas-minicart {
    @include media-breakpoint-up(lg) {
        max-width: 30rem;
    }

    .offcanvas-heading {
        .qty {
            display: inline-block;
            margin-left: 0.5rem;
            padding: 0.25rem 0.6rem;
            font-size: $font-size-sm;
            line-height: 1;
            background: $black;
            color: $white;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            @include border-radius(50%);
                        
            &.empty {
                display: none;
            }
        }
    }
    .items-total {
        display: none;
    }
    .subtitle {
        display: block;
        margin-bottom: 1rem;
    }
    .actions {
        .primary {
            &.checkout {
                padding: $btn-padding-y $btn-padding-x;
            }
        }
    } 
}
.minicart-items-wrapper {
    .minicart-items {
        .product-item {
            .product.actions {
                .edit {
                    display: none;
                }
                .delete {
                    color: $gray-500;
                }
            }
        }
    }
}