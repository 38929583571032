.page-header {
    background: $black;
    z-index: $zindex-dropdown;
}

header {
    position: relative;
    margin-bottom: map-get($spacers, 0);

    @include media-breakpoint-up($main-breakpoint) {
        z-index: 101;
    }

    @include media-breakpoint-down($main-breakpoint) {
        margin-bottom: 0;
    }


    .sub-header {
        padding: 1rem;

        @include media-breakpoint-down($main-breakpoint) {
            padding-top: $navbar-brand-padding-y;
            padding-bottom: $navbar-brand-padding-y;
        }

        .logo {
            text-align: center;

            img {
                height: 3.5rem;
                max-width: 182px;
            }
        }
    }

    .header {
        padding: map-get($spacers, 3);

        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }

        .logo {
            img {
                height: 3.5rem;
                max-width: 182px;
            }
        }

        .btn {
            .text {
                display: none;
            }
        }

        &-wishlist {
            &:hover {
                .icon {
                    @include svg-icon(1.3125rem, 1.3125rem, $icon-heart, $white);
                }
            }
            .icon {
                @include svg-icon(1.3125rem, 1.3125rem, $icon-heart-hover, $white);
            }
        }

        &-account {
            &:hover {
                .icon {
                    @include svg-icon(1.3125rem, 1.3125rem, $icon-account, $white);
                }
            }
            .icon {
                @include svg-icon(1.3125rem, 1.3125rem, $icon-account-hover, $white);
            }
        }
    }
}

.breadcrumbs-wrapper {
    margin-top: map-get($spacers, 1);

    .breadcrumb-item {
        a {
            text-decoration: underline;
        }
    }
}

.navbar-backdrop {
    @include media-breakpoint-up($main-breakpoint) {
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
        background-color: $offcanvas-backdrop-bg;
        opacity: 0.5;
    }
}
