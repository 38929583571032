.block {
    &.filter {
        .filter {
            &-subtitle {
                display: none;
            }
            &-options {
                &-title {
                    margin-bottom: 1rem;
                    padding-bottom: 0.5rem;
                    font-size: $h2-font-size;
                    font-weight: $font-weight-medium;
                    border-bottom: 1px solid $gray-200;
                }
                &-content {
                    margin-bottom: $margin-y;
                }
            }
        }
    }
}