.checkout-index-index {
    @include media-breakpoint-down($main-breakpoint) {
        background: $gray-100;
    }
    @include media-breakpoint-up($main-breakpoint) {
        background: linear-gradient(to right, $gray-100 50%, $white 50%);
    }

    .sub-header {
        margin-bottom: $spacer / 2;
        padding-top: 0;
        padding-bottom: 0;
        
        .logo {
            display: block;
            margin: 0.5rem auto;
            padding: 0.5rem 0;

            @include media-breakpoint-down($main-breakpoint) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .progress-authetication-wrapper {
        margin-bottom: $spacer;

        .authentication-wrapper {
            .action-auth-toggle {
                @include button-variant($btn-login, $btn-login);
                @include large-button-padding($btn-padding-y, 1.5rem);
                border-radius: $btn-border-radius-lg;
                text-transform: uppercase;
                letter-spacing: 1px;

                &:hover,
                &:focus {
                    background-color: $white;
                    color: $black;
                    @include transition($btn-transition);
                }
            }

            .actions-toolbar {
                .primary {
                    .action-login {
                        display: flex;
                        align-items: center;
                        border-radius: $btn-border-radius-lg;
                        text-transform: uppercase;
                        letter-spacing: 1px;

                        &:before {
                            margin-right: map-get($spacers, 2);
                            @include svg-icon(0.875rem, 0.875rem, $icon-sign-in, $white);
                            font-weight: $font-weight-light;
                        }

                        &:hover,
                        &:focus {
                            background-color: $white;
                            color: $black;
                            @include transition($btn-transition);

                            &::before {
                                @include svg-icon(0.875rem, 0.875rem, $icon-sign-in, $primary);
                            }
                        }
                    }
                }
            }
        }

        .opc-progress-bar {
            >li {

                &._active,
                &._complete {
                    font-weight: 400;
                }

                &._active {
                    span {
                        border-bottom-color: $red;
                    }
                }
            }
        }
    }

    .opc-estimated-wrapper {
        background: $white;

        .minicart-wrapper {
            .showcart {
                @include button-outline-variant($btn-primary, $btn-primary);
                border-radius: 0;

                &:hover, &:focus {
                    color: $white;
                }
            }
        }
    }
    .opc {
        .checkout-shipping-address {
            .form-control {
                border: 0;
            }
        }
        .checkout-payment-method {
            .discount-code {
                margin-bottom: 2rem;

                .payment-option-title {
                    margin-bottom: 1rem;
                }
            
                div.mage-error {
                    position: absolute;
                    top: 3rem;
                }
                
                .form-control {
                    height: $input-height;
                    padding-left: 20px;
                    box-shadow: none;
                    border-radius: $border-radius-lg 0 0 $border-radius-lg;
                    border-color: $white;
                    border-width: 1px;

                }
                .action-apply {
                    @include large-button-padding($btn-padding-y, 1.5rem);
                    color: $white;
                    background-color: $black;
                    border-color: $black;
                    border-radius: $btn-border-radius-lg;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    @include media-breakpoint-down($main-breakpoint) {
                        padding: 0.5rem 1rem;
                    }

                    &:hover {
                        color: $black;
                        background-color: $white;
                        border-color: $black;
                    }
                }
            }

            .payment-method {
                .payment-method-title {
                    display: flex;
                    align-items: center;

                    .radio {
                        margin-top: 0;
                    }
                }
                
                .payment-method-title {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    .field {
        &[name="shippingAddress.telephone"] {
            .input-group-text {
                background: $gray-400;
                border: 0;
            }
        }
    }

    .fieldset.address {
        @include make-row(($grid-gutter-width / 2));

        .field {
            @include make-col-ready(($grid-gutter-width / 2));
            @include make-col(12);

            &[name="shippingAddress.firstname"],
            &[name="shippingAddress.lastname"] {
                @include make-col(6);
            }

            &[name="shippingAddress.postcode"] {
                @include make-col(12);
            }

            &[name="shippingAddress.city"] {
                @include make-col(12);
            }

            &[name="shippingAddress.company"],
            &[name="shippingAddress.vat_id"],
            &[name="shippingAddress.telephone"],
            &[name="shippingAddress.fax"] {
                @include make-col(6);
            }
        }
    }

    .actions-toolbar {
        >.primary {
            .primary {
                @include large-button-padding($btn-padding-y, 1.5rem);
                border-radius: $btn-border-radius-lg;

                &.disabled {
                    pointer-events: none;
                }
            }
        }
    }

    .opc-block-summary {
        >.title {
            display: block;
        }

        .totals-tax {
            .amount {
                padding-right: 0;
                text-align: right;
            }
        }
    }
    .checkout-agreements-block {
        .amasty-gdpr-consent {
            @extend .choice;

            a {
                text-decoration: underline;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }
            div.mage-error {
                color: $danger;
            }
        }
        ._required,
        .required {
            label {
                &:after {
                    content: "*";
                    color: $red;
                }
            }
        }
    }
    .checkout-agreements { 
        .checkout-agreement {
            .action-show {
                font-size: $font-size-base;
                margin-bottom: 0;
                text-decoration: underline;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }
        }
    }

    textarea {
        height: auto;
    }
}

.checkout-success-wrapper {
    padding: 3.5rem 0;
}

.checkout-index-index, 
.checkout-cart-index {
    .item-info, 
    .product {
        .message {
            &.notice {
                background-color: transparent;
            }
        }
    }
}