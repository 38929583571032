.cart-container {
    .cart {
        .item-info {
            .cart-price-block {
                margin-bottom: 0.25rem;
            }
            .product-item-name {
                margin-bottom: 0.25rem;
            }
            .qty.spinbox {
                margin-bottom: 0.5rem;
            }
            .max-qty-message {
                font-size: .75rem;
                color: $gray-500;
            }

            .actions-toolbar {
                .action-edit {
                    @include svg-icon(0.85rem, 0.85rem, $icon-pen, $gray-600);
                }
                .action-delete {
                    @include svg-icon(0.85rem, 0.85rem, $icon-trash-alt, $gray-600);
                }
            }
        }
    }
    .spinbox {
        &-down,
        &-up {
            i {
                &::before {
                    display: none;
                }
            }
        }
    }
    .discount {
        background: $gray-200;

        .title {
            justify-content: flex-start;

            &::before {
                @include svg-icon(1.125rem, 1.5rem, $icon-gift, $body-color);
            }

            strong {
                padding-top: 0.3rem;
            }

            &::after {
                margin-left: auto;
            }
        }
    }
}
