.category {
    &-view {
        .page-title {
            font-size: 3.313rem;
            text-align: center;
            margin-bottom: 2.125rem;
            line-height: 3.625rem;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
        }

        &_bottom {
            margin-top: 2.5rem;
        }

        &.categories-list {
            .page-title {
                text-align: left;
            }

            .category-description,
            .category-seo_description  {
                text-align: left;
                max-width: 100%;
            }
        }
    }

    &-description,
    &-seo_description {
        max-width: 43.75rem;
        text-align: center;
        margin: 2.5rem auto;
        font-size: 0.813rem;
        line-height: 1rem;
        .page-content {
            line-height: 1;
        }
        .readmore-wrapper {
            .showmore {
                i {
                    margin-right: 0;
                }
            }
        }
    }
}

.catalog-category-view {
    .sidebar-actions {
        border-bottom: 1px solid $gray-b2;
        margin-bottom: 2.5rem;
        text-align: center;
        .btn.btn-filter {
            font-size: 0.813rem;
            font-weight: 700;
            text-transform: uppercase;
            &:focus {
                box-shadow: none;
            }
        }
    }
}
