h1, h2, h4 {
    font-size: 0.875rem;
    text-transform: uppercase;
    line-height: 1rem;
    font-weight:  $font-weight-bold;
    margin-bottom: 1.25rem;
}

p {
    line-height: 1.375rem;
    font-size: 0.813rem;
}
