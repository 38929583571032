.switcher-language {
    display: inline-block;
    padding: 15px 0;

    .switcher-label {
        margin-right: 0.2em;
    }

    .switcher-view {
        cursor: pointer;

        @include media-breakpoint-up(lg) {
            color: $gray-200;
        }

        span {
            text-decoration: underline;
        }

        .icon-angle-down {
            @include svg-icon(0.875rem, 0.875rem, $icon-chevron-down, $white);
            vertical-align: middle;
        }
    }

    &.dropdown {
        ul {
            padding-left: 0;
            list-style-type: none;
            margin: 0;
        }

        .dropdown-menu {
            box-shadow: $box-shadow;
            padding: $spacer;
            min-width: 14rem;
            border-radius: 0;
            line-height: 1.6;
            border-width: 0;
            text-transform: none;
            @include media-breakpoint-down(md) {
                box-shadow: none;
                background: transparent;
                padding: 1rem 1rem 1rem 0;
            }

            .dropdown-title {
                padding-bottom: 1rem;
            }

            a {
                display: block;
                color: $black;

                @include media-breakpoint-down($main-breakpoint) {
                    color: $gray-700;
                }
            }
        }
    }

    &#switcher-language-mobile {
        margin-left: 0;

        .dropdown-menu {
            position: static;
            width: 100%;
            left: 0;
            margin-bottom: 1rem;
            padding: 1rem 2rem;
            padding-left: 0;
            border-width: 0;
            border-color: initial;
            box-shadow: initial;
        }
    }
}

.navbar {
    .switcher-language {
        display: none;
        .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            width: auto;
            max-width: 250px;
        }
    }
}

.language-mobile-switcher {
    display: none;
    padding: 1rem;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
        display: flex;
    }
}
