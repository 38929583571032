.amblog-main-content {
    .amblog-container-list {
        margin-top: $spacer;
    }
    .amblog-post-container {
        padding-bottom: 2rem;
        border: 0;
        line-height: $line-height-lg;

        +.amblog-post-container {
            padding-top: 2rem;
            border-top: $border-width solid $gray-200;
        }
        .amblog-image {
            @include media-breakpoint-down(md) {
                margin-bottom: 1rem;
            }
        }
        .amblog-date {
            color: $gray-500;
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
        }
        .amblog-title {
            margin-top: 0;
            font-size: $h4-font-size;
            margin-bottom: 0.5rem;

            a {
                color: $headings-color;
            }
        }
        .amblog-social-container {
            text-align: right;

            ul {
                padding-left: 0;
                list-style-type: none;

                li {
                    display: inline-block;

                    a {
                        color: $body-color;
                    }
                    + li {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
        &.post-view {
            border: 0;
        }
    }
}
.amblog-index-post {
    .amblog-post-container {
        line-height: $line-height-lg;
        
        a {
            color: $warning;
        }
        .post-meta {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: $spacer;

            .amblog-date {
                color: $body-color;
            }
        }
        .amblog-image {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        figcaption {
            font-size: $font-size-sm;
            font-style: italic;
        }
    }
}
.amblog-links {
    text-align: center;
}
.amblog-element-block {
    .amblog-title {
        margin-bottom: 0.5em;
        font-family: $headings-font-family;
        font-size: $h3-font-size;
        font-weight: $headings-font-weight;
        color: $primary;

        @include media-breakpoint-down(sm) {
            &:after {
                float: right;
                @include svg-icon(1rem, 1rem, $icon-plus, $primary);
            }
            &.-active {
                &:after {
                    @include svg-icon(1rem, 1rem, $icon-minus, $primary);
                }
            }
        }
    }
    .amblog-categories {
        padding-left: 0;
        list-style-type: none;

        .amblog-category {
            position: relative;
            padding-left: 1rem;

            &:before {
                position: absolute;
                left: 0;
                @include svg-icon(1rem, 1rem, $icon-angle-right, $primary);
            }
        }

    }
    .amblog-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        .amblog-item {
            position: relative;
            font-size: $h5-font-size;

            +.amblog-item {
                padding-top: 0.5rem;
                border-top: 1px solid $gray-200;
            }
            a {
                color: $body-color;
            }
            .amblog-date {
                color: $gray-500;
                font-size: $font-size-sm;
                font-weight: 600;
            }
        }
    }
    .amblog-tag {
        @extend .btn;
        @include button-variant($success, $success);
        margin-right: 0.35rem;
        margin-bottom: 0.5rem;
        font-size: $font-size-sm;
        font-weight: $font-weight-bolder;
    }
}
[data-set='sidebar-recentpost'] {
    padding: 1rem;
    margin-bottom: 2rem;
    background: $gray-200;

    @include media-breakpoint-down(sm) {
        padding: 0;
        margin-bottom: 1rem;
        background-color: transparent;
    }
    .post-item-details {
      margin-bottom: map-get($spacers, 2);
    }
    .amblog-dates {
        display: none;
    }
}
.amblog-social-container {
    .amblog-list {
        margin-bottom: 0;

        $icons: (facebook: $icon-facebook,
            twitter: $icon-twitter,
            instagram: $icon-instagram,
        );

        $iconWidth: 1rem;

        @each $class,
        $icon in $icons {
            .amblog-icon {
                a {
                    &.-#{$class} {
                        &::before {
                            @include svg-icon($iconWidth, $iconWidth, $icon, $primary);
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}
