body {
    &:has(.ampromo-overlay.-show) {
        height: 100%;
        overflow: hidden;
    }
}

.ampromo-overlay {
    display: flex;
    align-items: center;

    .ampromo-popup-wrapper {
        position: relative;
        margin: auto;
        padding-top: map-get($spacers, 3);
        background-color: $white;

        @include media-breakpoint-up(lg) {
            max-width: 57.5rem;
        }
        .ampromo-popup-container {
            .ampromo-item {
                border: none;
                margin: 0 0.938rem 1rem;
                padding: 1rem 1.5rem;
                @include media-breakpoint-down(md) {
                    margin: 0 1rem 1rem;
                }

                .ampromo-items-form {
                    padding: 0;
                }
            }
        }
    }

    .ampromo-gallery {
        width: 100%;
        margin: 0 0 1.875rem;
        padding: 0;

        .slick-track {
            margin: auto;
        }
    }

    .ampromo-options {
        .label {
            padding-left: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .ampromo-popup-wrapper {
        .ampromo-slick {
            .slick-next {
                right: -1.5rem !important;
            }

            .slick-prev {
                left: -1.5rem !important
            }
        }
    }
}

.ampromo-items-add {
    margin-top: 0.625rem;
    padding: 0.75rem;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
    text-align: center;

    a {
        border-bottom: 1px dashed $primary;
    }
}

.page-wrapper,
.checkout-index-index {
    .ampromo-items-add {    
        background-color: $white;    
        border-color: darken($secondary, 5);
    }
}

.checkout-cart-index {
    .ampromo-items-add {
        background-color: $gray-200;
        border-color: $gray-300;
    }
}
.checkout-index-index {
    .page-main {
        .ampromo-items-add {
            &:before {
                display: none;
            }

            @include media-breakpoint-up(lg) {
                width: 47%;
            }
        }
    }
}

.ampromo-popup-wrapper {
    .ampromo-popup-container {
        .ampromo-item {
            position: relative;
            &:hover {
                background-color: transparent;
            }
            &:hover:after,
            &:hover:before {
                -webkit-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }

            &:before {
                border-bottom: 0.063rem solid $black;
                border-left: 0.063rem solid $black;
                -webkit-transform-origin: 0% 100%;
                -moz-transform-origin: 0% 100%;
                -ms-transform-origin: 0% 100%;
                -o-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
            }

            &:after,
            &:before {
                z-index: 3;
                content: '';
                position: absolute;
                top: 0;
                left: -0.063rem;
                right: -0.063rem;
                bottom: -0.063rem;
                box-sizing: border-box;
                -webkit-transform: scale(0);
                -ms-transform: scale(0);
                -o-transform: scale(0);
                transform: scale(0);
                transition: .27s;
                pointer-events: none;
            }

            &:after {
                border-top: 0.063rem solid $black;
                border-right: 0.063rem solid $black;
                -webkit-transform-origin: 100% 0%;
                -moz-transform-origin: 100% 0%;
                -ms-transform-origin: 100% 0%;
                -o-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
            }
        }
    }
}

.ampromo-item {
    .ampromo-popup-wrapper {
        .ampromo-item-image {
            margin: 0.5rem auto 1rem;
        }
    }
}

.ampromo-item-title {
    color: $black;
    margin-bottom: 1rem;
    min-height: 2.8rem;

    .ampromo-title {
        font-size: 0.875rem;
        font-weight: 600;
    }
}
.ampromo-item {
    .ampromo-items-form {
        .ampromo-options {
            margin: 0;
            padding: 0;
        }
    }
}

.ampromo-item {
    .ampromo-items-form {
        .product-info-price {
            padding: 0;
            margin-bottom: 1.1rem;
            .price-base-price.tax.weee {
                .price {
                    font-size: 0.875rem;
                    font-weight: $font-weight-bold;
                }
            }

            .price-new-price {
                .price-new-price.tax.weee {
                    .price-wrapper {
                        font-size: 0.75rem;
                        font-weight: $font-weight-normal;
                        line-height: 0.75rem;
                    }
                }
            }
        }
    }
}

.ampromo-options {
    .label {
        padding: 0.313rem 1rem;
        color: $gray-700;
    }
    .control {
        border-radius: 0.25rem;

        .super-attribute-select {
            background-color: $gray-100;
            border-color: $gray-100;
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba(209, 209, 209, 0.5);
                background-color: #DDDDDD;
                border-color: #D6D6D6;
            }
        }
    }
}

.slick-prev.slick-arrow,
.slick-next.slick-arrow {
    font-size: 0;
    &:before {
        position: absolute;
        content: '';
        display: block;
        width: 0.7rem;
        height: 0.7rem;
        transform: rotate(45deg);
    }

    &:focus {
        box-shadow: none;
    }
}

.slick-prev.slick-arrow {
    &:before {
        left: 53%;
        border-left: 0.125rem solid $black;
        border-bottom: 0.125rem solid $black;
    }
}

.slick-next.slick-arrow {
    &:before {
        left: 47%;
        border-right: 0.125rem solid $black;
        border-top: 0.125rem solid $black;
    }
}

.slick-dots {
    display: flex;
    justify-content: center;
    list-style: none;
    padding-inline-start: 0;

    li {
        &.slick-active {
            button {
                &:before {
                    opacity: 0.75;
                }
            }
        }

        button {
            font-size: 0;
            &:focus,
            &:hover {
                box-shadow: none;
                &:before {
                    opacity: 0.75;
                }
            }

            &:before {
                content: '\A';
                width: 0.25rem;
                height: 0.25rem;
                border-radius: 50%;
                background: $black;
                display: inline-block;
                opacity: 0.25;
            }
        }
    }
}

$ampromo-items__background-color: #fff;
$ampromo-overlay__background-color: rgba(0, 0, 0, .8);
$ampromo-item__border: 1px solid #ededed;
$ampromo-overlay-close__background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMCAxMG0wLTEwTDEgMTEiIHN0cm9rZT0iI0IyQjJCMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==);

.ampromo-overlay {
    & {
        position: fixed;
        top: -9999px;
        left: 0;
        z-index: 10000;
        width: 100%;
        height: 100%;
        background-color: $ampromo-overlay__background-color;
        text-align: center;
        opacity: 0;
        transition: opacity 0.4s linear;
    }
    &.-show {
        top: 0;
        opacity: 1;
    }

    h2 {
        margin-left: 0.75rem;
        text-align: left;
    }

    .ampromo-item-buttons,
    .ampromo-title {
        text-align: center;
    }

    .ampromo-popup-title .ampromo-counter {
        font-weight: bold;
    }

    .ampromo-item {
        & {
            position: relative;
            box-sizing: border-box;
            padding: 20px;
            margin: 0 8px 7px;
            border: $ampromo-item__border;
            transition: .6s;
        }

        &:hover {
            background: #ccc;
        }
    }

    .ampromo-item .fieldset .field {
        margin-bottom: 10px;
    }

    .ampromo-item .fieldset .field .label {
        margin-bottom: 3px;
    }

    .ampromo-popup-title {
        margin: 0 auto 20px;
    }

    .ampromo-close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        background: $ampromo-overlay-close__background-image no-repeat;
        background-size: contain;
        font-size: 0;
        cursor: pointer;
        opacity: .8;

        &:hover {
            opacity: 1;
            transition: opacity .2s ease;
        }
    }

    .ampromo-button {
        box-shadow: none;
        border: none;
    }

    .ampromo-item.-selected {
        background: #1f78c2;
    }

    .ampromo-item.-selected .ampromo-options {
        max-height: 690px;
    }

    .ampromo-item-image {
        display: block;
        margin: 7px auto;
    }

    .ampromo-title {
        margin: 0;
    }

    .slick-dots {
        position: initial;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 1000px) and (min-width: 701px) {
    .ampromo-overlay .ampromo-gallery {
        width: 570px;
        margin: 0 auto;
    }

    .ampromo-popup-wrapper {
        padding: 24px 54px;
    }
}

@media only screen and (max-width: 700px) {
    .ampromo-overlay .ampromo-gallery {
        width: 280px;
        margin: 0 auto;
    }

    .ampromo-popup-wrapper {
        padding: 24px 54px;
    }
}

@media only screen and (max-width: 400px) {
    .ampromo-overlay .ampromo-gallery {
        margin: 0 auto 30px auto;
    }

    // rewrite slick styles
    .ampromo-overlay .slick-prev,
    .ampromo-overlay .slick-next {
        display: none !important;
    }

    .ampromo-popup-wrapper {
        padding: 15px;
    }
}

.ampromo-popup-wrapper {
    & {
        max-width: 960px;
        width: 100%;
    }
    .slick-dotted.slick-slider {
        margin-bottom: 3rem;
    }
}
