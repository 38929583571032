.alert, .message, .warning {
    border-left-width: 5px;

    &-warning, &.warning {
        background-color: $white;
        border-color: $black;
        border-left-color: $warning;
        color: $primary;
    }
    &-danger, &.danger {
        background-color: $white;
        border-color: $black;
        border-left-color: $danger;
        color: $primary;
    }
    &-success, &.succes {
        background-color: $white;
        border-color: $black;
        border-left-color: $success;
        color: $primary;
    }
}