// Check `vendors/boostrap/mixins` for more mixins

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($arguments) {
  -webkit-animation: $arguments;
  -moz-animation: $arguments;
  animation: $arguments;
}

@mixin heading($arguments) {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    text-transform: uppercase;
    letter-spacing: 1px;
    @include font-size($arguments);
}

@mixin hover-underline($bottom) {
  position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: $bottom;
        left: 0;
        width: 100%;
        height: 1px;
        background: currentColor;
        transform-origin: left center;
        transform: scaleX(0);
        transition: transform 0.4s cubic-bezier(.11,.81,.37,1);
    }
    &:hover {
        text-decoration: none;

        &:after {
            transform: scaleX(1);
        }
    }
}
@mixin large-button-padding($btn-padding-y, $btn-padding-x) {
    padding: $btn-padding-y $btn-padding-x;
}

@mixin button-styles($width) {
    border: none;
    font-weight: $font-weight-light;
    font-size: 0.875rem;
    margin-top: 1.875rem;
    min-width: $width;
    padding: 0.6rem 2.5rem;

    span {
        letter-spacing: 1.5px;
    }
    &:hover {
        font-weight: $font-weight-bold;
    }
    &:active {
        background-color: $white;
    }
    &:focus {
        box-shadow: none;
    }
}

@mixin button-black() {
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    min-width: 12rem;

    &:hover,
    &:focus {
        font-weight: $font-weight-bold !important;
        background-color: $black  !important;
        color: $white  !important;
        box-shadow: none !important;
    }
}

@mixin block-title() {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: $font-weight-bold ;
    text-transform: uppercase;
    margin-bottom: 1.875rem;
}

@mixin block-title-light() {
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: $font-weight-light;
    margin-bottom: 1.875rem;
    letter-spacing: 0.125rem;
    text-align: left;
}

@mixin title() {
    text-transform: uppercase;
    font-weight:  $font-weight-light;
}
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function svg-icon-color($svg, $old-fill, $new-fill) {
    $replaced-svg: str-replace($svg, $old-fill, $new-fill);
    $replaced-svg-url: escape-svg('#{$replaced-svg}');
    @return $replaced-svg-url;
}

// SVG Icon mixin with color support:
@mixin svg-icon($width, $height, $svg, $color: '#212529', $fromcolor: '#ffffff') {
    content: '';
    display: inline-block;
    width: $width;
    height: $height;
    background-image: svg-icon-color($svg, $fromcolor, $color);
    background-repeat: no-repeat;
    background-position: center;
}

// SVG Icon mixin with color support and hover color
@mixin svg-icon-hover($width, $height, $svg, $color: '#212529', $hovercolor: '#212529', $fromcolor: '#ffffff') {
    position: relative;
    content: '';
    display: inline-block;
    width: $width;
    height: $height;
    background-repeat: no-repeat;
    background-position: center;

    &::after,
    &::before {
        position: absolute;
        left: 0;
        content: '';
        display: inline-block;
        width: $width;
        height: $height;
        background-repeat: no-repeat;
        background-position: center;
        transition: opacity 0.3s ease;
        pointer-events: none;
    }

    &:after {
        background-image: svg-icon-color($svg, $fromcolor, $hovercolor);
        opacity: 0;
    }

    &:before {
        background-image: svg-icon-color($svg, $fromcolor, $color);
        opacity: 1;
    }

    &:hover {
        &::after {
            opacity: 1;
        }

        &::before {
            opacity: 0;
        }
    }
}

@mixin svg-mask($svg, $width: 1rem, $height: 1rem, $color: $primary, $hoverColor: darken($color, 5)) {
    content: '';
    display: inline-block;
    width: $width;
    height: $height;
    -webkit-mask-image: $svg;
    mask-image: $svg;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    background-color: $color;
  
    &:hover {
      background-color: $hoverColor;
    }
}
  