.hero {
    &.hero-banner {
        .hero-banner-header {
            &.color-light {
                color: $white;
            }
            &.color-dark {
                color: $black;
            }
        }
    }

    &-background-wrapper {
        overflow: hidden;
        .hero-image {
            position: inherit;
            width: 100%;
            height: 100%;
            text-align: center;

            img {
                position: inherit;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
