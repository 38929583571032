.btn-spinbox {
    @include button-variant($btn-spinbox, $gray-300);
    border-radius: 0;
    border-color: transparent;

    &:hover, &:focus {
        background-color: $btn-spinbox;
        border-color: $gray-300;
    }
}
.btn-primary {
    padding: map-get($spacers, 2) map-get($spacers, 6);
    text-transform: uppercase;
    border-radius: $btn-border-radius-lg;
    letter-spacing: 1px;

    @include media-breakpoint-down($main-breakpoint) {
        padding: map-get($spacers, 1) map-get($spacers, 2);
    }
}
.btn-secondary {
    border-radius: $btn-border-radius-lg;
    letter-spacing: 1px;
}
.btn-white {
    border: 1px solid $black;
    letter-spacing: 1px;

    &:hover, &:active {
        color: $white;
        background-color: $black;
        border-color: $white;
    }
}

.btn {
    &.primary, &-primary {
        &:hover, &:focus {
            color: $black !important;
            background-color: $white !important;
            border-color: $black !important;
        }
    }
}
.btn-cta {
    border-radius: $btn-border-radius-lg;
    text-transform: uppercase;
    letter-spacing: 1px;

    &:hover, &:focus {
        background-color: $white;
        color: $black;
        @include transition($btn-transition);
    }
}
.actions-toolbar {
    > .primary {
        .primary {
            @include large-button-padding(0.5rem, 1.5rem);
            border-radius: $btn-border-radius-lg;    
        }
    }
}
