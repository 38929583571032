.geoip-wrapper{
    .modal-popup {
        &._show {
            z-index: 1065 !important;
            background: rgba(0,0,0, .5);
        }
    }
}

.modals-wrapper {
    .amgdprcookie-modal-container {
        background: rgba(0,0,0, .5);
        width: 100%;
    }
}
