/*------------------------------------------------------------*\
    CMS
\*------------------------------------------------------------*/

.cms-page-view {
    .page-title {
        margin-top: 0;
        margin-bottom: 1.143em;
        text-transform: uppercase;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: uppercase;
    }

    p {
        line-height: $line-height-lg;
    }

    .page-content {
        a {
            text-decoration: underline;
        }
    }
}
.widget.block {
    margin-bottom: 0;
}

.widget-product-grid {
    .product-item {
        .product-item-info {
            padding: 1rem 0;
        }

        .product-item-name {
            .product-item-link {
                min-height: 5rem;
                color: $black;
            }
        }

        a {
            text-decoration: none;
        }

        .tocart {
            font-size: $font-size-sm;
        }
    }
}

.cms-no-route {
    .page-main {
        &.container {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }
}