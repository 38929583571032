.ampromo-overlay {
    .ampromo-items-content {
        .ampromo-popup-container {
            .ampromo-item {
                margin: 0.75rem;
                @include media-breakpoint-down(md) {
                    margin: 0.75rem 0.469rem;
                }
                &:hover {
                    background-color: transparent;
                }

                &:after,
                &:before {
                    content: none;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            max-width: 57.16rem;
        }
    }
}

.ampromo-gallery[data-count="1"] {
    @include media-breakpoint-up(lg) {
        width: 280px;
    }
}
.ampromo-item {
    .ampromo-items-form {
        width: 100%;

        .ampromo-item-title {
            margin-bottom: 0;
            min-height: 0;

            .ampromo-title {
                margin-bottom: 0.75rem;
                text-align: left;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .product-info-price{
            .price-base-price,
            .price-new-price {
                text-align: left;
            }
        }
    }
}

.ampromo-overlay {
    .ampromo-items-content {
        .ampromo-popup-container {
            .ampromo-item {
                padding: 0;
                display: flex;
                position: relative;
            }
        }
    }
}

.ampromo-container-wrapper {
    top: 100%;
    left: 0;
    width: 100%;
    padding-top: 0.4rem;
}

.ampromo-image-container {
    display: flex;
    align-items: center;
    min-height: 25rem;
    position: relative;
    @include media-breakpoint-down(sm) {
        min-height: 15.625rem;
    }
}

.ampromo-image-wrapper {
    margin: 0;
    width: 100%;
    .ampromo-item-image {
        width: 100%;
        margin: 0.438rem 0;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(68,68,68,.03);
    }
}



