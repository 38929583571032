.checkout-cart-index {
    .page {
        &.messages {
            .messages {
                margin-top: map-get($spacers, 6);
            }
        }
    }
	.cart-empty {
		margin-top: map-get($spacers, 6);
		margin-bottom: map-get($spacers, 6);
		padding: map-get($spacers, 4) 0 map-get($spacers, 3) 0;
		border: ($border-width * 2) solid $gray-200;
		text-align: center;
    }
    .cart-usp {
        @include media-breakpoint-up(lg) {
            padding: map-get($spacers, 4);
        }
    }
}
.cart-container {
    margin-top: map-get($spacers, 3);
    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-up(lg) {
        margin-top: map-get($spacers, 6);
        margin-bottom: map-get($spacers, 6);
    }

    .cart-block {
        border: 0;

        .btn-link {
            color: $gray-500;
            font-weight: $font-weight-normal;
            text-transform: uppercase;
        }
        .btn-outline {
            @extend .btn-white;
            font-weight: $font-weight-normal;
            border-radius: $btn-border-radius-lg;
            text-transform: uppercase;
        }
        .action {
            &.continue {
                font-weight: $font-weight-light;
                text-transform: uppercase;
            }
            &.update {
                padding: map-get($spacers, 2) map-get($spacers, 4);
                font-size: $font-size-sm;
                font-weight: $font-weight-light;
                text-transform: uppercase;
                border-radius: $btn-border-radius-lg;
                letter-spacing: 1px;

                @include media-breakpoint-only(md) {
                    padding: $btn-padding-y-sm $btn-padding-x-sm;
                }
            }
        }
        h2.summary {
            @include media-breakpoint-down($main-breakpoint) {
                padding-bottom: 0.5rem;
                font-size: $h3-font-size;
            }
        }
    }
    .cart {
		.item-info {
            .cart-price-block {
                padding-bottom: 0;
                color: $gray-500;
                font-weight: $font-weight-normal;
			}
            .product-item-name {
                font-size: $font-size-lg;
            }
            .item-options {
                .item-option {
                    color: $gray-500;
                }
            }
            .spinbox {
                .qty {
                    border-color: $white;
                }
            }
            .subtotal {
                padding-right: $btn-padding-y;

				.title {
                    color: $gray-500;
                    font-weight: $font-weight-normal;
				}
				.price {
                    color: $gray-500;
					font-weight: $font-weight-normal;
				}
			}
            .actions-toolbar {
				li {
					a {
                        color: $gray-500;

						&:hover {
							color: $gray-500;
						}
					}
				}
				.action-edit, .action-delete {
                    padding: $btn-padding-y 0.3rem;
                    color: $gray-600;
				}
			}
        }
    }
    .discount {
        background: $gray-200;

        .title {
            background: $gray-200;
            justify-content: flex-start;

            strong {
                font-weight: $font-weight-light;
            }

            &::before {
                margin-right: map-get($spacers, 2);
                @include svg-icon(1rem, 1rem, $icon-gift, $body-color);
            }

            &::after {
                margin-left: auto;
            }

        }
        .content {
            padding-bottom: map-get($spacers, 6);
        }
        .fieldset {
            &.coupon {
                .label {
                    display: none;
                }
            }
        }
        .form-control {
            border: 0;
        }
        .actions-toolbar {
            .primary {
                border-radius: $btn-border-radius-lg;
            }
        }
    }
    .table {
        caption {
            display: none;
        }
    }
    .cart-totals {
        .mark,
        .amount {
            font-weight: $font-weight-normal;
        }
    }
    .grand {
        &.incl, &.excl {
            font-size: $font-size-sm;

                &.totals {
                .mark,
                .amount {
                    font-size: $font-size-base;
                }
            }
        }
    }
    .cart-payments {
        padding: 1.714em 0 0.143em;
        text-align: left;

        img {
            margin-right: 0.2rem;
            margin-bottom: 0.7rem;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);

            @include media-breakpoint-down($main-breakpoint) {
                margin-top: 0.5rem;
                margin-right: 0.5rem;
            }
        }
    }
    button.checkout {
        width: 100%;
        padding: $btn-padding-y $btn-padding-x;
        font-size: $font-size-base;
        @include button-variant($btn-primary, $btn-primary);

        @include media-breakpoint-only(md) {
            padding: $btn-padding-y-sm $btn-padding-x-sm;
            font-size: $font-size-sm;
        }
    }
}

.catalog-product-view {

    .spinbox {
        &-down,
        &-up {
            i {
                &::before {
                    display: none;
                }
            }
        }
    }
}  

.checkout-cart-index {
    $icons: (
        heart: $icon-heart-hover,
        minus: $icon-minus,
        plus: $icon-plus,
    );

    $iconWidth: 1rem;

    @each $class,
    $icon in $icons {     
        .icon,
        .fa {
            &-#{$class} {
                &::before {
                    @include svg-icon($iconWidth, $iconWidth, $icon, $primary);
                    vertical-align: middle;
                }
            }
        }
    }
}