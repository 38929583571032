
.filter-current.amshopby-filter-current {
    .items {
        padding-left: 0;

        .item {
            padding-left: 0;
            display: flex;
            align-items: center;
        }
    }
    .amshopby-remove {
        position: absolute;
        right: 1.125rem;
        left: auto !important;
        width: 10px;
        height: 10px;

        &:before, &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $black;
        }

        &::before {
            transform: rotate(-45deg);
        }
        &::after {
            transform: rotate(45deg);
        }
        &:hover, &:focus {
            &:before, &:after {
                background-color: $gray-600;
            }
        }
    }
    .amshopby-filter-value {
        margin-left: 0.5rem;
    }
}
.filter-actions {
    .filter-clear {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem 1rem;
        color: $gray-900;
        font-size: $h6-font-size;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid $gray-300;
        
        &:hover {
            background: $gray-200;
        }
        &:before {
            margin-right: 0.7rem;
            @include svg-icon(0.875rem, 0.875rem, $icon-times, $primary);
        }
    }
}
.filter-options {
    .items {
        .item {
            input {
                display: none;
            }

            +.item {
                margin-top: 0.75rem;
            }

            a {
                display: flex;
                align-items: center;
                vertical-align: middle;
                color: $gray-800;

                &.am_shopby_link_selected {
                    .indicator {
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            background: #000;
                            top: 2px;
                            right: 2px;
                            bottom: 2px;
                            left: 2px;
                            border-radius: 2px;
                            -moz-border-radius: 2px;
                            -webkit-border-radius: 2px;
                        }
                    }
                }

                .indicator {
                    display: inline-block;
                    width: map-get($spacers, 3);
                    height: map-get($spacers, 3);
                    position: relative;
                    margin-right: 0.5rem;
                    border: 1px solid $gray-300;
                    border-radius: 3px;
                    color: $gray-300;

                    &.radio {
                        border-radius: 50%;

                        &:before {
                            border-radius: 50%;
                        }
                    }
                }

                .label {
                    margin-right: 0.25rem;
                }

                .count {
                    font-size: 1rem;
                    font-weight: normal;
                    color: #d0d0d0;

                    &::before {
                        display: none;
                    }

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
    .filter-options-item {
        .amshopby-slider-container {
            margin-top: 15px;

            .ui-slider-horizontal {
                height: 5px;
                background-color: $gray-300;
            }
            .ui-slider-range {
                height: 5px;
                background-color: $gray-700;
            }
            .ui-slider-handle {
                margin-top: -6px;
                background: $black;
                width: 18px;
                height: 18px;
                box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5);

                &:hover, &:active {
                    background: $gray-400;
                }
            }
            .amshopby-slider-display {
                margin-top: 1rem;
            }
        }
    }
}
.filter-options-content {
    .am-swatch-wrapper {
        display: inline-block;
        
        .am-swatch-link {
            display: flex;
            align-items: center;
        }

        &>.input {
            &.-hidden {
                display: none;
            }
        }
    }
    .amshopby-search-box {
        input {
            @extend .form-control;
        }
    }
    .am-show-more {
        display: none;
        padding: 0;
        font-size: $h6-font-size;
        text-transform: lowercase;

        &:after, &:before {
            background: $secondary;
        }

        &.-active {
            display: flex;
        }
    }
}

.am-filter-items-category_ids {
    .amshopby-link-selected {
        .label {
            font-weight: $font-weight-bold;
        }
    }

    ul.items-children {
        margin: $spacer;
        
        li.item {
            line-height: 0.5;
        }
    }
}