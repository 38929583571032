@import "module/_base";
@import "module/_modal";
@import "module/_variables";

.amgdprjs-bar-template {
    z-index: $zindex-fixed !important;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    .amgdprcookie-bar-block {
        display: flex;
    }

    .amgdprcookie-bar-container {
        padding: 1rem;
        padding-top: 2rem;
        background-color: $gray-300;
        box-shadow: 0 0 1rem rgba(0,0,0,0.2);
        }

        .amgdprcookie-policy {
            flex: 1;
        }
    .amgdprcookie-buttons-block {
        display: block;
        flex: 1;

        .amgdprcookie-button {
            width: 100%;
            padding: 0.5rem 1rem;

            &.-settings {
                display: inline-block;
                border: 0;
                color: $body-color;

                &:hover {
                    background-color: transparent;
                }
            }
            + .amgdprcookie-button {
                margin-top: 0.5rem;
            }
        }
    }
}

.amgdprcookie-done {
    margin-bottom: 1rem;
    color: $body-color;
    background-color: transparent;
    border: 0;
}
.amgdprcookie-accept-all {
    @include button-variant($primary, $primary);
    margin-bottom: 1rem;}

.amgdprcookie-table {
    font-size: 0.75rem;

    thead,
    tbody {
        th,
        td {
            padding-left: 0.75rem;
        }
    }
}


.amgdprcookie-modal-template,
.amgdprcookie-bar-container {

    .amgdprcookie-policy,
    .amgdprcookie-text
     {
        color: $black;
    }

    .amgdprcookie-header {
        color: $primary;
    }

    .amgdprcookie-link,
    .amgdprcookie-policy a {
        color: $black;
        text-decoration: underline;
    }

    .amgdprcookie-button {
        &.-save,
        &.-allow {
            @include button-variant($primary, $primary);
        }
    }

    .amgdprcookie-button.-settings {
        background-color: transparent;
        text-transform: none;
        font-weight: 400;
    }
}

.amgdprcookie-groups-modal {
    .modal-inner-wrap {
        @include media-breakpoint-down($main-breakpoint) {
            margin: 0 auto;
            width: 100%;
        }
    }
}
