.aw_bold {
    font-weight: bold;
}

.aw-no-display {
    display: none;
}

.faq-index-index,
.faq-category-index,
.faq-article-index {
    .page-title-wrapper {
        margin: map-get($spacers, 3) 0;
    }
}

.faq-category-index,
.faq-article-index {
    .category-menu {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        text-indent: -15px;
        margin-left: 15px;

        li {
            margin-bottom: map-get($spacers, 4);
            line-height: map-get($spacers, 2);

            &:before {
                @include svg-icon(1rem, 1rem, $icon-caret-right, $primary);
                vertical-align: middle;
                margin-right: map-get($spacers, 1);
            }
        }
    }
}

.faq-article-index {
    .sidebar-block {
        .block-title {
            margin-bottom: 2rem;
            font-size: $h4-font-size;
        }
    }
}

.article-list {
    display: inline-block;
    margin-bottom: map-get($spacers, 5);
    text-align: left;

    .category-name {
        .category-icon {
            margin-right: 0.25rem;
            max-width: map-get($spacers, 5);
            max-height: map-get($spacers, 5);
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        text-indent: -15px;
        margin-left: 15px;

        li {
            margin-bottom: map-get($spacers, 4);
            line-height: 1.5;

            &:before {
                @include svg-icon(1rem, 1rem, $icon-caret-right, $primary);
                vertical-align: middle;
                margin-right: 5px;
            }
        }

        li.image-item {
            padding-left: 4rem;
            background-repeat: no-repeat !important;
            background-size: 2rem !important;
        }
    }

    ul.aw-list-style {
        position: relative;

        li {
            list-style-type: none;
        }
    }

    .more-articles-link {
        padding-left: 4rem;
    }
}

//ARTICLE QUESTION FORM
.faq-question-from-container {
    margin-top: 40px;
    width: 33%;

    .faq-question-form .legend {
        width: auto;
    }
}

#aw-faq__helpfulness {
    margin-top: 4rem;
}

#aw__helpfulness-voting {
    padding: 0 map-get($spacers, 3);

    a {
        text-decoration: underline;
    }

    a:hover {
        cursor: pointer;
    }

    .like:after {
        content: '|';
        display: inline-block;
        margin: map-get($spacers, 2);
    }
}

.aw-faq-search {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 3rem;

    form {
        width: 75%;
        display: inline-block;

        .aw-search-field {
            .input-text {
                float: left;
                border-color: $black;
                
                @include media-breakpoint-up($main-breakpoint) {
                    width: 65%;
                    margin-right: 2rem;
                }
            }
        }
        .action.primary {
            height: $input-height;
        }
    }
}

.faq-article-index, .faq-category-index {
    .aw-faq-search {
        display: none;
    }
}

.search-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .results-list {
        text-align: left;
        width: 50%;

        h3 {
            font-weight: normal;
            color: $gray-900;
        }

        &.article-list ul li {
            padding-left: 0;
        }
    }
}

@include media-breakpoint-down($main-breakpoint) {

    .aw-columns-1,
    .aw-columns-2,
    .aw-columns-3 {
        .aw-column {
            width: 100%;
        }
    }

    // SEARCH
    .aw-faq-search {
        form {
            display: inline-block;
            width: 100%;

            .action.primary {
                padding: 0.5rem 2.5rem;
            }
        }
    }

    //ARTICLE QUESTION FORM
    .faq-question-from-container {
        width: 66% !important;
    }
}

@include media-breakpoint-down(sm) {
    .aw-faq-search {
        form {
            #aw-faq-search-field {
                clear: both;
            }
            .action.primary {
                width: 100%;
                margin-top: 1rem;
                padding: 0.5rem 1.5rem;
            }
        }
    }

    //ARTICLE QUESTION FORM
    .faq-question-from-container {
        width: 100% !important;
    }
}
