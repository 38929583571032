.geoip-wrapper {
    .modal-popup .modal-inner-wrap {
        max-width: $modal-md;
    }

    .modal-slide._inner-scroll .modal-inner-wrap {
        border-radius: 1rem;
        overflow-y: hidden;

        .modal-content {
            text-align: center;

            p {
                margin-top: 1rem;
            }

            .btn {
                margin-top: 1rem;
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            width: calc(100% - 15px);
            max-width: none;
        }
    }

    .modal-popup .action-close {
        display: none;
    }
}
