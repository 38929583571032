.navbar {
    z-index: 1001;

    .main-menu-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .navbar-collapse {
        align-items: flex-start;
        max-width: 21.25rem;
        overflow: visible;
    }

    .children-list {
        padding: 0;
    }

    ul.navbar-nav {
        padding: 3.313rem 2.188rem 0;
        display: flex;
        flex-direction: column;
    }

    ul.nav-list {
        position: absolute;
        right: auto;
        top: 0;
        margin: 0;
        height: 100%;
        width: 100%;
        flex-direction: column;
        overflow: auto;
        display: flex;
        box-sizing: border-box;
        border: none;
        transition: left .25s ease-out, opacity .25s ease-in;
        background: $white;
        &.slide-menu {
            left: -100%;
        }

        &:not(.navbar-nav) {
            padding: 2.375rem 2.188rem 2.813rem;
        }

        .nav-item {
            list-style: none;
            &.dropdown {
                .nav-link {
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translate(0, -50%) rotate(45deg);
                        width: 8px;
                        height: 8px;
                        border-top: 1px solid $black;
                        border-right: 1px solid $black;
                    }
                }
            }

            .nav-link span,
            .back-link,
            .view-all {
                position: relative;
                font-weight: 500;
                text-transform: uppercase;
            }

            .nav-link {
                display: block;
                padding: 0 0.6rem 0 0;
                margin-bottom: 1rem;
                font-size: 0.813rem;
                span {
                    display: inline-block;
                    font-size: 0.813rem;
                    line-height: 0.813rem;
                }

                &:after {
                    display: none;
                }

                &:hover {
                    span {
                        text-decoration: underline;
                    }
                }
            }

            .back-link {
                display: block;
                margin-bottom: 3rem;
                font-size: 1.125rem;
                line-height: 1.375rem;
                cursor: pointer;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: -1rem;
                    transform: translate(0, -50%) rotate(45deg);
                    width: 12px;
                    height: 12px;
                    border-bottom: 2px solid $black;
                    border-left: 2px solid $black;
                }
            }

            .view-all {
                display: block;
                padding: 0.625rem 2.188rem;
                margin: 0 -2.188rem 1rem;
                background: #f7f7f7;
            }

            img {
                display: none;
            }
        }

        &.hidden-level {
            opacity: 0;
            left: 100%;
        }

        &.visible-level {
            left: 0;
            opacity: 1;
            z-index: 2;
        }

        .contact-item {
            margin: 0 -2.188rem;
            padding: 1.375rem 2.188rem 2.813rem;
            background: #f7f7f7;
            flex-grow: 1;
            font-weight: 500;
            .contact-block {
                a {
                    display: block;
                    margin-bottom: 0.75rem;
                }
            }

            .switcher-language {
                padding: 0;
                margin: 0 0 0.75rem;
            }
        }
    }

    .menu-images {
        position: absolute;
        left: 100%;
        height: 100%;
        width: 634px;
        overflow: hidden;
        pointer-events: none;
        &__image {
            position: absolute;
            display: block;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 5;
            width: auto;
            max-width: initial;
            opacity: 0;
            transition: left .25s ease-in-out, transform .25s ease-in-out, opacity .25s ease-in-out;
            transform: translate(-100%, 0);
            pointer-events: none;
            &.current {
                opacity: 1;
                transform: translate(0);
            }

            &.slide-right {
                //opacity: 1;
                left: 100%;
                transform: translate(0);
            }

            &.next-visible {
                opacity: 1;
                z-index: 4;
            }
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
