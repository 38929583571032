.header-minicart {
    &:focus,
    &:hover {
        .icon {
            @include svg-icon(1.3125rem, 1.3125rem, $icon-shopping-bag-hover, $white);
        }
    }

    .icon {
        @include svg-icon(1.3125rem, 1.3125rem, $icon-shopping-bag, $white);
    }

    .counter {
        display: flex;
        align-items: center;
        justify-content: center;
        right: -0.4rem;
        bottom: 0.85rem;
        background: $gray-400;
        color: $black;
    }
}

.block-content {
    .subtitle {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

}

.product-item-details {
    .product-item-name {
        font-weight: $font-weight-bold;
        font-size: 0.875rem;
    }

    .details-qty.qty {
        margin-top: 0.625rem;

        .label {
            text-transform: uppercase;
        }
    }

    .product.options {
        text-transform: uppercase;

        .label {
            font-weight: $font-weight-light;
        }

        .content {
            font-size: 0.813rem;
        }
    }
}

.offcanvas-footer.block-content {
    .action.primary.checkout {
        @include button-black();
    }

    .subtotal {
        text-transform: uppercase;
        font-weight: $font-weight-light;
        font-size: 0.813rem;
    }
}

.product-item-details {
    font-size: 0.813rem;

    .product.actions {
        .secondary {
            margin-top: 1.25rem;
            font-size: 0.875rem;

            .action.delete {
                text-transform: uppercase;
                color: $black;
                font-weight: $font-weight-light;
                border-bottom: 1px solid $black;

                &:hover {
                    font-weight: $font-weight-medium;
                    text-decoration: none;
                }
            }
        }
    }
}


.secondary {
    .action.viewcart.btn.btn-secondary {
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        font-size: 0.875rem;
        min-width: 13rem;
        border: $gray-200;

        &:hover,
        &:active {
            background-color: $gray-200;
            font-weight: $font-weight-bold;
            box-shadow: none;
        }

    }
}