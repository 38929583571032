.offcanvas-backdrop {
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 950;

    @include media-breakpoint-down(xs) {
        top: 56px;
    }
}

.offcanvas {
    top: 72px;

    @include media-breakpoint-down(xs) {
        top: 56px;
    }
}

.offcanvas-top {
    top: 0;
    bottom: auto;
    transform: translateY(-100%);
    max-width: 100%;
}
.offcanvas-heading {
    text-align: center;

    .title {
        line-height: 1rem;
        text-transform: uppercase;
    }
}

.offcanvas-body {
    .subtitle.empty {
        text-align: center;
        font-weight: $font-weight-light;
        font-size: 0.75rem;
        text-transform: none;
    }
}
