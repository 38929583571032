.categories-grid {
    .item {
        &:after {
            content: " ";
            position: absolute;
            left: 0;
            right: 0;
            height: 100%;
            bottom: 0;
            background: linear-gradient(15deg, #000 -40%, transparent 55%);
            z-index: 3;
        }
    }
}

.catalog-category-view {
    .categories-grid {
        .item {
            .block-content {
                padding: 1rem 5rem 1rem 1rem;
                background: transparent;
                color: $white;
                flex-direction: column;
                align-items: baseline;
                z-index: 10;

                .product-name,
                .category-subtitle {
                    text-align: left;
                }

                .product-name {
                    color: $white;
                    font-weight: 700;
                }

                .category-subtitle {
                    font-weight: 300;
                    margin: 0;
                }

                .view-all {
                    position: absolute;
                    right: 1rem;
                    top: 50%;
                    transform: translate(0, -50%);
                    font-weight: 500;
                    &:before,
                    &:after {
                        content: '';
                    }

                    &:before {
                        display: block;
                        position: absolute;
                        bottom: 2px;
                        width: 100%;
                        height: 1px;
                        background: $black;
                        opacity: 0;
                        transition: opacity .3s;
                    }

                    &:after {
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-top: 4px solid transparent;
                        border-bottom: 4px solid transparent;
                        border-left: 6px solid $white;
                    }
                }
            }
            .block-content {
                .category-subtitle {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                .block-content {
                    padding: 1rem;
                    border-radius: 0;
                    background-color: $white;
                    align-items: center;
                    .product-name,
                    .category-subtitle {
                        color: $black;
                        text-align: center;
                    }

                    .view-all {
                        display: none;
                    }
                }
            }

            &:hover {
                .view-all {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.cms-index-index {
    .categories-grid {
        &.row {
            @include media-breakpoint-down(md) {
                margin-left: -15px;
                margin-right: -15px;
            }
        }

        &_title {
            @include media-breakpoint-down(md) {
                margin-top: 2rem;
            }
        }

        .item {
            &.big-block {
                .block-content {
                    position: absolute;
                    padding: 0;
                    width: auto;
                    text-align: left;
                    background: transparent;
                    z-index: 4;
                    bottom: 2rem;
                    left: 2rem;
                    min-height: auto;
                    .product-name {
                        text-align: left;
                        font-weight: $font-weight-bold;
                        line-height: 2.625rem;
                    }

                    .tags {
                        color: $white;
                        font-size: 2.375rem;
                        text-transform: uppercase;
                        margin-bottom: 0;
                        line-height: 2.625rem;
                        @include media-breakpoint-down(md) {
                            font-size: 0.75rem;
                        }
                    }

                    .btn {
                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                        margin-top: 1.875rem;
                    }
                }

                &:hover {
                    .block-content {
                        .btn {
                            box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, .5);
                        }
                    }
                }
            }

            &.small-block {
                &:after {
                    display: none;
                }

                .block-content {
                    background-color: $white;
                    .product-name {
                        color: $black;
                    }
                }
            }
        }
    }
}
