.category {
    &-view {
        .page-title {
            margin-bottom: map-get($spacers, 4);
        }

        &.overview {
            margin-bottom: map-get($spacers, 6);
        }
    }

    &-description {
        margin-bottom: map-get($spacers, 1);

        .page-content {
            position: relative;
            line-height: $line-height-md;

            &.collapsable {
                margin-bottom: map-get($spacers, 2);
                max-height: 5rem;
                overflow: hidden;
                @include transition(max-height 200ms ease-in-out);

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 7rem;
                    @include gradient-y(transparent, $white);
                }
            }
        }

        .readmore-wrapper {
            display: block;

            .showmore {
                color: $body-color;

                span {
                    font-size: $h6-font-size;
                }

                i {
                    margin-right: map-get($spacers, 2);
                    color: $primary;
                }
            }

            .more {
                display: block;

                span {
                    @include svg-icon(0.75rem, 0.75rem, $icon-plus-circle, $primary);
                    vertical-align: middle;
                }
            }

            .less {
                display: none;
            }
        }

        &.open {
            .page-content {
                max-height: none;
                @include transition(max-height 200ms ease-in-out);

                &:after {
                    display: none;
                }
            }

            .readmore-wrapper {
                .more {
                    display: none;
                }

                .less {
                    display: block;

                    span {
                        @include svg-icon(0.75rem, 0.75rem, $icon-minus-circle, $primary);
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    &-bottom-description {
        @include media-breakpoint-up($main-breakpoint) {
            .page-content {
                &.collapsable {
                    max-height: none;

                    &:after {
                        display: none;
                    }
                }
            }

            .readmore-wrapper {
                display: none;
            }
        }
    }
}

.catalog-category-view {
    .page-title {
        @include media-breakpoint-down($main-breakpoint) {
            text-align: center;
        }
    }
}
