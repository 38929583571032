.footer {
    .block.block-newsletter {
        .block-content {
            p {
                font-size: 1.125rem;
                line-height: 1.375rem;
                font-weight: $font-weight-bold;
            }

            .subscribe {                
                .action.subscribe {
                    @include button-black();
                    border-top-right-radius: 23rem !important; 
                    border-bottom-right-radius: 23rem !important; 

                    @include media-breakpoint-down(sm) {
                        min-width: 9rem;
                    }
                }
            }
        }
    }

    .block.subscribe-note {
        .info {
            font-size: 0.813rem;
            line-height: 0.938rem;
        }
    }
}
