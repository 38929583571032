.customer-container-wrapper {
    .page-title-wrapper {
        .page-title {
            font-size: 0.785rem;
            line-height: 1rem;
            font-weight: $font-weight-light;
        }
    }

    .login-container {
        .block-title {
            @include block-title();
        }
    }

    .actions-toolbar {
        .action.login.primary,
        .action.create.primary {
            @include button-black();
            margin-top: 1rem;

            &:hover {
                & > span:before {
                    font-weight: $font-weight-bold;
                }
            }
        }

        .action.remind {
            padding: 0;
            margin-left: 0.75rem;
            margin-top: 1rem;
            font-weight: $font-weight-light;

            &:hover,
            &:focus {
                box-shadow: none;
                text-decoration: underline;
            }
        }
    }

    .action.create.primary {
        min-width: 16rem;
    }
}
.category-view {
    .page-title-wrapper {
        .page-title {
            font-size: 3.3rem;
            line-height: 3.6rem;
            text-transform: uppercase;
            font-weight: $font-weight-medium;

            @include media-breakpoint-down($main-breakpoint) {
                font-size: 2rem;
                line-height: 1.5;
            }
        }
    }
    .category-attribute  {
        .page-content {
           .sr_text {
               line-height: 1;
           }
        }
    }
}

.form-create-account {
   .fieldset.create {
       .legend {
           font-size: 1rem;
           text-transform: uppercase;
       }
   }

    .label {
        font-size: 0.813rem;
    }

    .password-strength-meter {
        margin-top: 1.2rem;
    }
}

.actions-toolbar {
    .secondary {
        .action.back {
            text-transform: uppercase;
            font-weight: $font-weight-normal;
            padding: 0;
            border:none;
            border-radius: 0;

            &:hover,
            &:focus {
                border-bottom: 2px solid $black;
                font-weight: $font-weight-bold;
                box-shadow: none;
            }
        }
    }

    .primary {
        .action.submit.primary {
            @include button-black();
            min-width: 15rem;
        }
    }
}
