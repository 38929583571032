.navbar {
    background-color: $black;
    z-index: 1001;

    &.navbar-expand-lg {
        box-shadow: $box-shadow-lg;
    }

    @include media-breakpoint-up(lg) {
        background-color: $white;
    }

    .navbar-header-left,
    .navbar-header-center,
    .navbar-header-right {
        @include media-breakpoint-down(xs) {
            width: calc(100% / 3);
        }
    }

    .navbar-brand {
        img {
            height: 3.5rem;
            padding-top: $navbar-brand-padding-y;
            padding-bottom: $navbar-brand-padding-y;
        }
    }

    .navbar-header-right {
        text-align: right;

        .btn {
            .icon {
                color: $white;
                font-size: $h4-font-size;
            }
        }

        .navbar-account {
            &:hover {
                .icon {
                    @include svg-icon(1.3125rem, 1.3125rem, $icon-account, $white);
                }
            }
            .icon {
                @include svg-icon(1.3125rem, 1.3125rem, $icon-account-hover, $white);
            }
        }

        .navbar-minicart {
            position: relative;
            padding-right: 0;

            &:focus,
            &:hover {
                .icon {
                    @include svg-icon(1.3125rem, 1.3125rem, $icon-shopping-bag, $white);
                }
            }
            
            .icon {
                @include svg-icon(1.3125rem, 1.3125rem, $icon-shopping-bag-hover, $white);
            }

            .counter {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1rem;
                height: 1rem;
                position: absolute;
                right: -0.5rem;
                bottom: 0.5rem;
                background: $white;
                text-align: center;
                border-radius: 50%;
                color: $primary;
            }
        }
    }

    .navbar-toggler {
        color: $white;

        .navbar-toggler-icon {
            background-image: escape-svg($navbar-dark-toggler-icon-bg);
        }
    }

    .navbar-nav {
        
        @include media-breakpoint-down($main-breakpoint) {
            padding-left: 0.25rem;
        }

        .nav-item {
            >.nav-link {
                margin: 0;
                padding: map-get($spacers, 3) 0;
                letter-spacing: 1.5px;
                text-transform: uppercase;

                @include media-breakpoint-up($main-breakpoint) {
                    margin: 0 map-get($spacers, 1);
                    padding: map-get($spacers, 3) map-get($spacers, 2);
                }
            }

            &:not(.show) {
                >.nav-link {
                    @include hover-underline(1rem);
                }
            }
        }

        @include media-breakpoint-up(lg) {
            >.nav-item:nth-child(1) {
                a {
                    margin-left: 0;
                    padding-left: 0;
                }
            }

            .dropdown-menu {
                ul {
                    >li {
                        a {
                            display: inline-block;
                            padding: 0.2rem 0;
                            @include hover-underline(0.2rem);
                        }
                    }
                }
            }
        }
    }
}
