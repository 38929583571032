footer.footer {
    margin-top: 0;
    padding: $padding-y 0 0;
    background: $white;
    box-shadow: $box-shadow-lg;
}

.footer {
    &-middle-wrapper {
        padding: $padding-y 0;
        padding-top: 4rem;
        background: $gray-200;

        .block-address {
            .contact-info {
                padding-left: 0;
                list-style: none;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .footer-socials {
            display: none;
        }


        @include media-breakpoint-down($main-breakpoint) {
            .footer-socials {
                display: block;
                text-align: center;
                padding: 2rem 0;

                ul {
                    padding: 0;
                    margin-bottom: 0;

                    li {
                        display: inline-block;
                        margin-right: 1rem;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                a {
                    display: inline-block;
                    width: 2.5rem;
                    height: 2.5rem;
                    color: $black;
                    font-size: 1.5rem;
                }
            }
        }
    }

    &-bottom-wrapper {
        padding: 3rem 0;
        background: $black;
        color: $white;


        .block-address {
            display: none;

            .block-title {
                display: none;
            }

            @include media-breakpoint-down(sm) {
                display: block;
                text-align: center;

                .contact-info {
                    padding: 2rem 0;
                    padding-top: 0;
                    list-style-type: none;

                    a {
                        color: $white;
                    }
                }
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            .footer-socials {
                display: none;
            }
        }
    }

    &-block {
        .block-title {
            @include heading($h4-font-size);

            &.label-collapse {
                &:after {
                    float: right;
                    @include svg-icon(1rem, 1rem, $icon-minus, $primary);
                }

                &.collapsed {
                    &:after {
                        @include svg-icon(1rem, 1rem, $icon-plus, $primary);
                    }
                }
            }
        }

        .block-content {
            line-height: $line-height-lg;

            a {
                @include hover-underline(-0.09rem);
            }

            ul {
                padding: 0;
                list-style-type: none;
            }
        }
    }

    &-payments {
        text-align: center;
        margin-top: $margin-y;
        margin-bottom: $margin-y;

        .payments {
            margin-bottom: $margin-y;

            @include media-breakpoint-down($main-breakpoint) {
                display: none;
            }
        }

        img {
            -webkit-filter: grayscale(100%);
            /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);

            @include media-breakpoint-down($main-breakpoint) {
                margin-top: 1rem;
                margin-right: 1rem;
                height: 1rem;
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            margin-bottom: 0;
        }
    }

    &-socials {
        text-align: right;

        @include media-breakpoint-down($main-breakpoint) {
            text-align: center;
            padding-top: 3rem;
        }

        ul {
            padding: 0;
            margin-bottom: 0;

            li {
                display: inline-block;
                margin-right: 1rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        a {
            color: $white;
            font-size: $h1-font-size;

            &:hover,
            &:focus {
                color: $gray-300;
            }
        }
    }

    $icons: (
        facebook: $icon-facebook,
        twitter: $icon-twitter,
        instagram: $icon-instagram,
        phone:$icon-phone,
        envelope: $icon-envelope
    );

    $iconWidthBlock: 1rem;
    $iconWidthSocial: 1.5rem;

    @each $class,
    $icon in $icons {
        .contact-info {
            .icon {
                &-#{$class} {
                    &::before {
                        margin-right: map-get($spacers, 1);
                        @include svg-icon($iconWidthBlock, $iconWidthBlock, $icon, $primary);
                        vertical-align: middle;
                    }
                }
            }
        }
        .footer-socials {
            .icon {
                &-#{$class} {
                        @include svg-icon($iconWidthSocial, $iconWidthSocial, $icon, $white);
                        vertical-align: middle;
                }
            }
        }
    }
}

.to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    display: none;
    z-index: 1000;
    cursor: pointer;

    @include media-breakpoint-down($main-breakpoint) {
        bottom: 15px;
    }

    .btn-to-top {
        position: relative;
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        background: $white;
        border: 1px solid $primary;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .icon-arrow-up {
                &::before {
                    margin-bottom: map-get($spacers, 1);
                    @include svg-icon(1.25rem, 1.25rem, $icon-arrow-up, $primary);
                }
            }
        }
    }
}

@include media-breakpoint-down($main-breakpoint) {
    .copyright {
        display: block;
        text-align: center;
    }
}
