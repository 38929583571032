.store-locator-link {
    padding: 0.375rem 0.75rem;

    .text {
        display: none;
    }
    .icon {
        &:before {
            @include svg-icon(1rem, 1rem, $icon-map-marker, $primary);
        }
    }
}
button.amlocator-button.amlocator-filter-attribute {
    background: $black;
    color: white;
}
.amlocator-filters-container {
    padding: 1rem 0;

    .amlocator-clear {
        color: $black;
    }
} 
