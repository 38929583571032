.header-account {
    .icon {
        @include svg-icon(1rem, 1rem, $icon-account, $body-color);
    }
}
.navbar-account {
    .icon {
        @include svg-icon(1rem, 1rem, $icon-account-navbar, $body-color);
    }
    .text {
        display: none;
    }
}

.customer-account-login,
.customer-account-forgotpassword,
.customer-account-create {
    .page-title {
        margin: map-get($spacers, 3) 0;
    }
    fieldset {
        margin-bottom: map-get($spacers, 3);

        legend {
            margin-bottom: 0;
            border-bottom: $border-width solid $gray-200;
        }
    }
    .block-customer-login,
    .block-new-customer,
    .form.password.forget,
    .form-create-account {
        padding-bottom: map-get($spacers, 3);

        @include media-breakpoint-down($main-breakpoint) {
            padding-bottom: map-get($spacers, 3);
        }
    }
}
.account {
    .page-title {
        margin-bottom: map-get($spacers, 3);
    }
    .page-main {
        margin: map-get($spacers, 4) auto;
    }
    .toolbar {
        @include clearfix();

        .limiter {
            float: right;
        }
    }
    .box {
        margin-bottom: map-get($spacers, 3);
    }
    .container-main {
        padding-top: map-get($spacers, 3);
        padding-bottom: map-get($spacers, 5);

        .actions-toolbar {
            padding-top: map-get($spacers, 2);
            margin-top: map-get($spacers, 3);
        }
    }
    .payment-method {
        dt {
            font-weight: 400;
        }
    }
    .items.addresses {
        display: flex;
        flex-wrap: wrap;
		padding: 0;
        list-style: none;
        
        .item {
            margin-right: map-get($spacers, 4);
            margin-bottom: map-get($spacers ,4);
        }
	}
}
.offcanvas-account {
    ul {
        padding: 0;
        list-style-type: none;
    }
}
.account-nav {
    .items {
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        .item {
            width: 100%;
            position: relative;
            border-bottom: $border-width solid $gray-100;

            strong {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 3px;
                    background: $primary;
                }
            }
            > a,
            > strong {
                display: block;
                width: 100%;
                padding: $nav-link-padding-y $nav-link-padding-x;
            }
            &.current {
                > a,
                > strong {
                    background: $gray-100;
                }
            }
        }
    }
}
.account-offcanvas-nav {
    .items {
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        .item {
            position: relative;

            strong {
                &:before {
                    content: '';
                    position: absolute;
                    left: -1rem;
                    height: map-get($spacers, 4);
                    width: 3px;
                    background: $primary;
                }
            }
            > a,
            > strong {
                display: block;
                padding-top: ($nav-link-padding-y / 2);
                padding-bottom: ($nav-link-padding-y / 2);
            }
            +.item {
                padding: map-get($spacers, 1) 0;
            }
        }
    }
}
.account-nav {
    &.block {
        margin-top: map-get($spacers, 3);
        margin-bottom: map-get($spacers, 5);

        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }
}
.block-dashboard-info,
.block-dashboard-addresses,
.block-reviews-dashboard,
.block-dashboard-orders,
.block-addresses-default,
.block-wishlist,
.block-compare,
.block-reorder {
    .block-title {
        padding-bottom: map-get($spacers, 2);
        margin-bottom: map-get($spacers, 2);
        border-bottom: 1px solid $gray-100;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h5-font-size);

        .edit,
        .view {
            margin-left: map-get($spacers, 3);
            @include font-size($font-size-sm);
        }
    }
    &.block {
        margin-bottom: map-get($spacers, 5);
    }
    .table th {
        border-top: 0;
    }
}
.block-addresses-list {
    .block-title {
        padding-bottom: map-get($spacers, 2);
        margin-bottom: map-get($spacers, 2);
        border-bottom: 1px solid $gray-100;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h5-font-size);

        .edit,
        .view {
            margin-left: map-get($spacers, 3);
            @include font-size($font-size-sm);
        }
    }
    .table th {
        border-top: 0;
    }
}
.block-dashboard-info, .block-dashboard-addresses, .block-addresses-default {
    .block-content {
        @include make-row();

        .box {
            @include make-col-ready();
            @include make-col(6);

            @include media-breakpoint-down(xs) {
                @include make-col(12);
            }
        }
    }
}
.block-addresses-list {
    margin-bottom: map-get($spacers, 4);
}
.fieldset-fullname {
    .fields {
        @include make-row(($grid-gutter-width / 2));

        .field {
            @include make-col-ready(($grid-gutter-width / 2));

            &.field-name-prefix {
                @include make-col(12);
            }
            &.field-name-firstname,
            &.field-name-lastname  {
                @include make-col(6);
            }
        }
    }
}
.form-address-edit {
    fieldset {
        &.street {
            margin-bottom: 0;
            
            legend {
                display: none;
            }
            > .control {
                @include make-row(($grid-gutter-width / 2));

                .field {
                    @include make-col-ready(($grid-gutter-width / 2));
                    @include make-col(6);

                    .label {
                        left: 1.4rem;
                    }
                }
                .field:only-child {
                    @include make-col(12);
                }
                .additional {
                    @include make-col(3);
                }
            }
        }
    }
}
