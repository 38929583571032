.product-reviews-wrapper {
    padding: map-get($spacers, 5) 0;
    margin-bottom: map-get($spacers, 4);
}

.product-reviews-summary {
    .rating-summary {
        display: inline-flex;
        position: relative;

        .label {
            display: none;
        }
    }
    .reviews-actions {
        display: inline-flex;
        @include font-size($font-size-sm);

        a {
            + a {
                margin-left: map-get($spacers, 3);
            }
        }
    }
    .rating-result {
        display: inline-block;

        > span {
            position: relative;
            display: block;
            overflow: hidden;
            z-index: 2;

            > span {
                display: none;
            }
        }
    }
}

.review-add {
    .review-control-vote {
        .icon {
            width: 1rem;
            height: 1rem;
        }
        input[type="radio"] {
            position: absolute;
            padding: 0;
            margin: -1px;
            width: 1px;
            height: 1px;
            border: 0;
            clip: rect(0, 0, 0, 0);
            overflow: hidden;

            &:checked {
                .icon {
                    fill: $yellow;
                }
            }
        }
        label {
            span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }
        // &:before {
        //     display: block;
        //     position: absolute;
        //     height: 1rem;
        //     color: $primary;
        //     font-size: 1rem;
        //     letter-spacing: 2px;
        //     vertical-align: top;
        //     //content: $review-icon-default $review-icon-default $review-icon-default $review-icon-default $review-icon-default;
        //     -webkit-font-smoothing: antialiased;
        //     z-index: 1;
        //     speak: none;
        // }
        // input[type="radio"] {
        //     position: absolute;
        //     padding: 0;
        //     margin: -1px;
        //     width: 1px;
        //     height: 1px;
        //     border: 0;
        //     clip: rect(0, 0, 0, 0);
        //     overflow: hidden;

        //     &:focus {
        //         & + label {
        //             &:before {
        //                 opacity: 1;
        //             }
        //         }
        //     }
        //     &:checked {
        //         & + label {
        //             &:before {
        //                 opacity: 1;
        //             }
        //         }
        //     }
        // }
        // label {
        //     display: block;
        //     cursor: pointer;
        //     position: absolute;

        //     span {
        //         border: 0;
        //         clip: rect(0, 0, 0, 0);
        //         height: 1px;
        //         margin: -1px;
        //         overflow: hidden;
        //         padding: 0;
        //         position: absolute;
        //         width: 1px;
        //     }
        //     &:before {
        //         color: $review-icon-color;
        //         height: 1rem;
        //         font-size: 1rem;
        //         font-weight: 900;
        //         letter-spacing: 2px;
        //         vertical-align: top;
        //         speak: none;
        //         opacity: 0;
        //         -webkit-font-smoothing: antialiased;
        //     }
        //     &.rating-5 {
        //         z-index: 2;

        //         &:before {
        //             content: $review-icon-default $review-icon-default $review-icon-default $review-icon-default $review-icon-default;
        //         }
        //     }
        //     &.rating-4 {
        //         z-index: 3;

        //         &:before {
        //             content: $review-icon-default $review-icon-default $review-icon-default $review-icon-default;
        //         }
        //     }
        //     &.rating-3 {
        //         z-index: 4;

        //         &:before {
        //             content: $review-icon-default $review-icon-default $review-icon-default;
        //         }
        //     }
        //     &.rating-2 {
        //         z-index: 5;

        //         &:before {
        //             content: $review-icon-default $review-icon-default;
        //         }
        //     }
        //     &.rating-1 {
        //         z-index: 6;
                
        //         &:before {
        //             content: $review-icon-default;
        //         }
        //     }
        // }
        // &:before {
        //     content: $review-icon-hover $review-icon-hover $review-icon-hover $review-icon-hover $review-icon-hover;
        // }
    }
}
.rating-summary {
    display: flex;
    align-items: center;

    .rating-label, .label {
        margin-right: ($spacer * 0.5);
        font-weight: $font-weight-bold;
    }
    .rating-result {
        display: inline-block;

        &:before {
            position: absolute;
            color: $gray-300;
            // @include svg-icon(1rem, 1rem, $review-icon, $gray-300); //Background repeat is needed
        }
        > span {
            position: relative;
            display: block;
            overflow: hidden;
            z-index: 2;

            &:before {
                color: $review-icon-color;
                font-weight: 900;
                // @include svg-icon(1rem, 1rem, $review-icon, $gray-300);
            }
            > span {
                display: none;
            }
        }
    }
}

.review-list {
    .block-title {
        font-size: $h3-font-size;
    }
    .review-items {
        padding: 0;
        list-style: none;

        .review-item {
            .review-title {
                font-size: $h5-font-size;
            }
            .review-ratings {
                margin-bottom: $spacer;
            }
            .review-date {
                color: $gray-500;
            }
        }
    }
}
.block-reviews-dashboard {
    .items {
        padding-left: $spacer;

        .item {
            .product-name {
                margin-right: $spacer;
            }
            .rating-summary {
                display: inline-flex;
                align-items: center;
            }
        }
    }
}
.table-reviews {
    caption {
        display: none;
    }
    .rating-summary {
        .label {
            display: none;
        }
    }
}
.customer-review {
    .reviews-actions {
        margin-bottom: map-get($spacers, 3);

        a {
            + a {
                margin-left: map-get($spacers, 3);
            }
        }
    }
    .rating-summary {
        margin-bottom: map-get($spacers, 3);
    }
    .review-title {
        font-weight: $font-weight-bold;
    }
    .review-date {
        margin-top: map-get($spacers, 2);
        color: $gray-500;
    }
}
