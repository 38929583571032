.contact-index-index {
    .form.contact {
        .legend, br {
            display: none;
        }
        .field.note {
            display: none;
        }
    }
}

.contact-container-wrapper {
    padding-bottom: $padding-y;
    background-color: $gray-200;

    .form-control {
        border-width: 0;
    }
    .block-left {
        padding-right: $grid-gutter-width / 4;
        
        @include media-breakpoint-only(md) {
            padding-right: $grid-gutter-width / 2;
        }

        @include media-breakpoint-up(md) {
            border-right: 1px solid $border-color;
        }
        @include media-breakpoint-up(lg) {
            padding-right: 4rem;
        }
        .primary {
            span {
                &:before {
                    margin-right: map-get($spacers, 2);
                    @include svg-icon(1rem, 1rem, $icon-paper-plane, $primary);
                }
            }   
        }

    }
    .block-right {
        padding-left: $grid-gutter-width / 4;

        @include media-breakpoint-down($main-breakpoint) {
            padding-top: $padding-y;
        }

        @include media-breakpoint-only(md) {
            padding-left: $grid-gutter-width / 2;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 4rem;
        }
    }

    input, textarea {
        border: 0;
        box-shadow: none;
    }

    .contact-info {
        padding-left: 0;
        list-style-type: none;
        line-height: $line-height-lg;

        a {
            @include hover-underline(-0.09rem);
        }
    }
}
