.products-grid {
    .product-col {
        margin-bottom: $margin-y;
    }
    .product-item {
        margin-bottom: $margin-y;
        text-align: center;

        &-info {
            position: relative;
            padding: map-get($spacers, 3) map-get($spacers, 4);
            background: #fff;
            border: 1px solid #fff;
            transition: .2s ease all;

            &:hover:after,
            &:hover:before {
                -webkit-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }

            &:before {
                border-bottom: 1px solid #000;
                border-left: 1px solid #000;
                -webkit-transform-origin: 0% 100%;
                -moz-transform-origin: 0% 100%;
                -ms-transform-origin: 0% 100%;
                -o-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
            }

            &:after,
            &:before {
                z-index: 3;
                content: '';
                position: absolute;
                top: -1px;
                left: -1px;
                right: -1px;
                bottom: -1px;
                box-sizing: border-box;
                -webkit-transform: scale(0);
                -ms-transform: scale(0);
                -o-transform: scale(0);
                transform: scale(0);
                transition: .27s;
                pointer-events: none;
            }

            &:after {
                border-top: 1px solid #000;
                border-right: 1px solid #000;
                -webkit-transform-origin: 100% 0%;
                -moz-transform-origin: 100% 0%;
                -ms-transform-origin: 100% 0%;
                -o-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
            }

            @include media-breakpoint-down($main-breakpoint) {
                padding: map-get($spacers, 4) map-get($spacers, 3);
            }
        }

        &-name {
            display: inline-flex;
            margin-bottom: map-get($spacers, 3);
            min-height: 2.8rem;

            @include media-breakpoint-down($main-breakpoint) {
                min-height: 4rem;
            }
        }

        &-link {
            &:hover {
                text-decoration: none;
            }
        }

        .product-label {
            position: absolute;
            top: 1.5rem;
            padding: map-get($spacers, 1) map-get($spacers, 2);
            border: 1px solid $primary;
            background: #fff;
            color: $primary;
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
            text-align: center;

            &.sale {
                left: 1.5rem;
            }

            &.new {
                left: 1.5rem;
            }
        }

        .price-box {
            font-weight: $font-weight-bold;
            text-align: center;

            .price-excluding-tax {
                display: block;
                margin-left: 0.25rem;
                font-size: $font-size-sm;
                font-weight: $font-weight-normal;

                &:after {
                    content: ' ' attr(data-label);
                    text-transform: lowercase;
                    font-weight: $font-weight-normal;
                }
            }
        }

        &-actions {
            .btn.primary {
                @include media-breakpoint-up(lg) {
                    padding-top: 0.7rem;
                    padding-bottom: 0.7rem;
                }
            }

            .towishlist {
                &:before {
                    @include svg-icon(1rem, 1rem, $icon-heart, $primary);
                }
            }

            .tocompare {
                &:before {
                    @include svg-icon(1rem, 1rem, $icon-compare, $primary);
                }
            }
        }

        .actions-primary,
        .tocart {
            @include media-breakpoint-down($main-breakpoint) {
                display: block;
            }
        }
    }
}

.toolbar-header {
    .toolbar-products {
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-down($main-breakpoint) {
            justify-content: space-between;
            margin-top: 2rem;

            .btn-layered,
            .btn-light {
                padding: 0.25rem 2.25rem;
                color: $black;
                background-color: $white;
                border-radius: 23rem;
                border: $black 1px solid;
                border-color: $black !important;
            }

            .btn-layered span::before {
                display: inline-block;
                margin-right: 0.25rem;
                @include svg-icon(1rem, 1rem, $icon-sliders-h, $primary);
            }

            .btn-light span::before {
                display: inline-block;
                margin-right: 0.25rem;
                @include svg-icon(1rem, 1rem, $icon-sort, $primary);
            }

            .modes {
                display: none;
            }
        }
    }
}

.toolbar {
    .pages {
        display: none;
    }

    .toolbar-amount {
        display: none;
    }

    .toolbar-limiter {
        display: none;
    }

    .toolbar-sorter {
        .input-group-append {
            display: none;
        }

        .btn-light {
            background-color: $white;
            border-color: $white;
            font-size: $font-size-base;
            text-transform: uppercase;

            &:focus,
            &:active {
                background-color: $white;
                border-color: $gray-200;
                box-shadow: none;
            }
        }
    }
}

.toolbar-footer {
    .pages {
        display: flex;
        justify-content: center;
        margin-top: $margin-y;
    }

    .btn-layered {
        display: none;
    }

    .pagination {
        .item {
            font-weight: $font-weight-medium;

            .page-link {
                font-size: $font-size-sm;
                border-radius: 50%;

            }

            +.item {
                margin-left: map-get($spacers, 3);
            }
        }
    }
}

.block {

    &.related,
    &.upsell,
    &.crosssell {
        .block-title {
            @include heading($h2-font-size);
        }
    }
}

.crosssell-related-upsell-wrapper {
    padding: 4rem 0;
    background: $gray-200;

    +.crosssell-related-upsell-wrapper {
        padding-top: 0;
    }
}
