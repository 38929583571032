.swatch-option {
    &.image {
        min-width: 34px;
        width: 34px !important;
        height: 34px !important;
        margin: 0.25rem 0.3rem 0.25rem 0;
        @include border-radius($border-radius-lg);
        box-shadow: 0 0 0 1px transparent;
    }
    &.disabled.disabled-by-admin {
        filter: grayscale(1);
        background: $gray-300;
        &:after {
            display: none;
        }
    }

    &:not(.disabled):hover, &:not(.disabled):focus {
        box-shadow: 0 0 0 2px $black;
    }
    &.text {
        min-width: 105px;
        padding: 1rem map-get($spacers, 3);
        background: $white;
        color: $black;
        border-color: $gray-300;
        border-radius: 0;
        font-size: $font-size-sm;

        &:not(.disabled):hover, &:not(.disabled):focus {
            border-color: $black;
            box-shadow: none;
        }
        &.selected {
            border-color: $black;
            box-shadow: none;
        }
    }
    &.selected {
        box-shadow: 0 0 0 2px $black;
    }
}
.swatch-opt {
    margin: map-get($spacers, 5) 0;
}
.swatch-attribute {
    margin-bottom: 0.25rem;

    &-label {
        font-weight: $font-weight-normal;
    }
    &.productcolor {
        @include media-breakpoint-up(lg) {
            padding-right: 4rem;
        }
    }
}
.products-grid {
    .swatch-attribute-options {
        display: flex;
        justify-content: center;

        .swatch-more {
            display: flex;
            align-self: center;
            padding-left: 0.2rem;

            &:before {
                @include svg-icon(1rem, 1rem, $icon-plus, $primary);
                font-weight: $font-weight-medium;
            }

            span {
                display: none;
            }
        }
    }
}

.product-options-wrapper {
    .swatch {
        &-option {
            &.text {
                margin-right: 0.6rem;
                padding: 1.3rem $spacer;
                font-size: $font-size-base;
            }
        }
    }
}
