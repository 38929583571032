.slick-slider {
    .slick-arrow {
        padding: 0;
        width: 40px;
        height: 40px;
        font-size: 0;
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &.slick-next {
            right: -30px;
            &:before {
                border-right: 2px solid $black;
                border-top: 2px solid $black;
                left: 47%;
            }

            @include media-breakpoint-down(xs) {
                right: 0;
            }
        }

        &.slick-prev {
            left: -30px;
            &:before {
                border-left: 2px solid $black;
                border-bottom: 2px solid $black;
                left: 53%;
            }

            @include media-breakpoint-down(xs) {
                left: 0;
            }
        }
    }
}

.products-grid {
    .slick-slider {
        .product-col {
            display: flex !important;
            @include media-breakpoint-down(md) {
                padding-right: 7.5px;
                padding-left: 7.5px;
            }
        }
    }
}

.home-product-list {
    .product-items {
        display: none;
        &.slick-initialized {
            display: block;
        }
    }
}
