.categories-grid {
    .item {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: map-get($spacers, 5);
        text-align: center;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            margin-top: $spacer;
            margin-bottom: 0;
        }

        img {
            width: 100%;
            opacity: 1;
            transition: transform 1s cubic-bezier(.17,.97,.77,.9);
            transform: scale(1);
        }

        .block-content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 map-get($spacers, 5);
            min-height: 4.5rem;
            background: $black;

            @include media-breakpoint-down(lg) {
                position: relative;
                min-height: 4rem;
                padding: 0 map-get($spacers, 2);
            }

            .product-name {
                position: relative;
                margin: 0;
                color: $white;
                text-transform: uppercase;
                text-align: center;
                font-weight: $font-weight-medium;
                letter-spacing: 1px;

                @include media-breakpoint-down(md) {
                    font-size: $h6-font-size;
                }
            }
        }

        &:hover {
            @include media-breakpoint-up(sm) {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    .category-info-wrapper {
        .seo-category-description {
            margin-top: 0;
        }
    }
}
