::-moz-selection { /* Code for Firefox */
 color: white;
 background: black;
}
::selection {
 color: white;
 background: black;
}

img {
    height: auto;
}
