.navbar {
    .main-menu-wrapper {
        .slide-menu.nav-list {
            .nav-item {
                .back-link {
                    font-weight: $font-weight-light;
                }
            }
        }
    }

    .navbar-nav {
        padding: 1rem;

        .nav-link {
            display: inline-block;

            span {
                line-height: 1rem;
                font-weight: $font-weight-medium;
            }
        }
        .nav-item {
            &.dropdown {
                &.yamm-fw {
                    position: relative;
                }

                .dropdown-toggle {
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 15;
                    padding: .8rem 1.1rem;
                    padding-right: 0;
                    color: $black;
                    cursor: pointer;

                    &::after {
                        @include svg-icon(1rem, 1rem, $icon-circle-plus, $primary);
                        border: none;
                    }
                }

                &.show {
                    .dropdown-toggle::after {
                        @include svg-icon(1rem, 1rem, $icon-circle-minus, $primary);
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                > .nav-link {
                    margin: 0;
                    padding: map-get($spacers, 3) 0;
                }
            }
        }
    }
}

.page-main {
  .block.block-cms {
      .sidebar-block {
          .block-title {
              font-size: 0.875rem;
          }
      }
  }
}
